import { CalendarToday } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Checkbox, Divider, Typography } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { useDeleteTask } from 'api/hooks/delete'
import { useGetTasks } from 'api/hooks/get'
import { usePatchTask } from 'api/hooks/patch'
import EllipsisMenu from 'components/common/EllipsisMenu'
import Flex from 'components/common/Flex'
import Loading from 'components/common/Loading'
import { useModal } from 'contexts/ModalContext'
import { useState } from 'react'
import { convertUtcToDate, style } from './AttendeeTasks'
import TaskCreateEditModal from './TaskCreateEditModal'
import TaskProgressModal from './TaskProgressModal'

const HostTask = ({ task, isCompleted }) => {
  const { mutate: deleteTask } = useDeleteTask()
  const [checked, setChecked] = useState(isCompleted)
  const { mutate: patchTask } = usePatchTask()
  const { showModal } = useModal()

  const handleChange = () => {
    setChecked(!checked)

    patchTask({
      taskPayload: {
        state: isCompleted ? 'PENDING' : 'COMPLETED',
        respond_by: task.respond_by,
      },
      task: {
        ...task,
        state: isCompleted ? 'PENDING' : 'COMPLETED',
      },
      isCompleted: !isCompleted,
    })
  }

  const handleDelete = () => {
    deleteTask({ task_id: task.id })
  }

  const menuContent = [
    {
      name: 'Edit task',
      onClick: () => showModal(<TaskCreateEditModal task={task} />),
    },
    {
      name: 'Edit task progress',
      onClick: () => showModal(<TaskProgressModal task={task} />),
    },
    {
      name: 'Delete task',
      confirm: true,
      onClick: handleDelete,
    },
  ]

  const numCompletedAssignees = task.assignees.filter(({ is_completed }) => is_completed).length
  const percentCompleted = Math.round((numCompletedAssignees / task.assignees.length) * 100)

  return (
    <>
      <Flex
        row
        card
        justifyContent="space-between"
        sx={{
          ...style.task,
          ...(isCompleted && style.completedTask),
        }}
        onClick={() => showModal(<TaskProgressModal task={task} />)}
      >
        <Flex row width="60%" gap={['4px', '8px']}>
          <Checkbox
            name={task.id}
            checked={checked}
            value={checked}
            onChange={handleChange}
            onClick={(e) => e.stopPropagation()}
          />
          <Box>
            <Typography
              sx={{
                wordBreak: 'break-word',
                minWidth: '150px',
                fontSize: ['15px', '16px'],
                ...(isCompleted && style.completedTaskMessage),
              }}
            >
              {task.message}
            </Typography>
            {task.respond_by && (
              <Flex row sx={style.taskDate}>
                <CalendarToday sx={{ fontSize: '12px' }} />
                <Typography variant="caption">{convertUtcToDate(task.respond_by)}</Typography>
              </Flex>
            )}
          </Box>
        </Flex>
        <Flex row flex={1} maxWidth="37.5%">
          <Flex row flex={1} justifyContent="space-around" gap="8px">
            <LinearProgress value={percentCompleted} />
            <Typography
              width="50px"
              fontSize="14px"
              color="text.secondary"
              textAlign="center"
            >{`${percentCompleted}%`}</Typography>
          </Flex>
          <Box marginRight="-5px">
            <EllipsisMenu items={menuContent} sx={{ fontSize: '18px' }} />
          </Box>
        </Flex>
      </Flex>
    </>
  )
}

const HostTasks = () => {
  const fetchIncompletedTasks = useGetTasks({ state: 'PENDING' })
  const fetchCompleteTasks = useGetTasks({ state: 'COMPLETED' })
  const { showModal } = useModal()

  if (fetchIncompletedTasks.isLoading || fetchCompleteTasks.isLoading)
    return <Loading size="32px" margin="16px" />

  return (
    <>
      <Button
        onClick={() => showModal(<TaskCreateEditModal />)}
        sx={{ alignSelf: 'flex-end' }}
        startIcon={<AddIcon />}
      >
        Create task
      </Button>
      {fetchIncompletedTasks.data.length === 0 ? (
        <Typography color="text.secondary">There are no open tasks!</Typography>
      ) : (
        <Flex gap="4px">
          {fetchIncompletedTasks?.data.map((task) => {
            return <HostTask key={`incompleted-${task.id}`} task={task} />
          })}
        </Flex>
      )}

      {fetchCompleteTasks.data.length > 0 && (
        <>
          <Divider />
          <Flex gap="4px">
            {fetchCompleteTasks.data.map((task) => {
              return <HostTask key={`completed-${task.id}`} task={task} isCompleted />
            })}
          </Flex>
        </>
      )}
    </>
  )
}

export default HostTasks
