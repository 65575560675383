import { CodeHighlightNode, CodeNode } from '@lexical/code'
import { AutoLinkNode, LinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { TRANSFORMERS } from '@lexical/markdown'
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
import { Typography } from '@mui/material'
import COLORS from 'assets/colors'
import Flex from 'components/common/Flex'
import CodeHighlightPlugin from 'components/common/TextEditor/plugins/CodeHighlightPlugin'
import ListMaxIndentLevelPlugin from 'components/common/TextEditor/plugins/ListMaxIndentLevelPlugin'
import exampleTheme from 'components/common/TextEditor/themes/ExampleTheme'
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_HIGH,
  PASTE_COMMAND,
} from 'lexical'
import { useEffect, useState } from 'react'

export const getEditorStateString = (editorState) => {
  let bodyString
  editorState.read(() => {
    bodyString = $getRoot().getTextContent()
  })
  return bodyString
}

const urlRegExp = new RegExp(
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/
)
const validateUrl = (url) => {
  return url === 'https://' || urlRegExp.test(url)
}

const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/

const EMAIL_REGEX =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/

const createLinkMatcherWithRegExp = (regExp, urlTransformer) => {
  return (text) => {
    const match = regExp.exec(text)
    if (match === null) {
      return null
    }
    return {
      index: match.index,
      length: match[0].length,
      text: match[0],
      url: urlTransformer(match[0]),
    }
  }
}

const MATCHERS = [
  createLinkMatcherWithRegExp(URL_REGEX, (text) => {
    return text
  }),
  createLinkMatcherWithRegExp(EMAIL_REGEX, (text) => {
    return `mailto:${text}`
  }),
]

const AutoLinkOnPastePlugin = () => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    const handlePaste = (event) => {
      const clipboardData = event.clipboardData
      const text = clipboardData?.getData('text')

      if (text?.startsWith('http://') || text?.startsWith('https://')) {
        event.preventDefault()
        editor.update(() => {
          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
              url: text,
            })
          }
        })
      } else {
        return false
      }
    }

    return editor.registerCommand(
      PASTE_COMMAND,
      (event) => {
        if (handlePaste(event) === false) return false
        return true
      },
      COMMAND_PRIORITY_HIGH
    )
  }, [editor])

  return null
}

const ReactHookFormPlugin = ({ disabled, isSubmitSuccessful, editorRef }) => {
  const [editor] = useLexicalComposerContext()

  // set editorRef
  useEffect(() => {
    if (editorRef && !editorRef.current) editorRef.current = editor
  }, [editor, editorRef])

  // loading state
  useEffect(() => editor.setEditable(!disabled), [disabled, editor])

  // reset editor on successful submit
  useEffect(() => {
    if (isSubmitSuccessful) editor.update(() => $getRoot().clear())
  }, [isSubmitSuccessful, editor])
}

const initialConfig = ({ value, name }) => {
  return {
    // can also do (editor) => {} but I coudln't figure out editor.setEditorState lmao
    editorState:
      value ||
      '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}',
    namespace: name,
    theme: exampleTheme,
    onError: (error) => {
      throw error
    },
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      LinkNode,
      AutoLinkNode,
    ],
  }
}

const LexicalEditor = ({
  name,
  onChange,
  placeholder,
  disabled,
  value,
  isSubmitSuccessful,
  editorRef,
  error,
  viewMode = false,
}) => {
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    const rootElement = editorRef?.current.getRootElement()
    const handleFocus = () => setIsFocused(true)
    const handleBlur = () => setIsFocused(false)

    if (rootElement) {
      rootElement.addEventListener('focus', handleFocus)
      rootElement.addEventListener('blur', handleBlur)
    }

    return () => {
      if (rootElement) {
        rootElement.removeEventListener('focus', handleFocus)
        rootElement.removeEventListener('blur', handleBlur)
      }
    }
  }, [editorRef])

  const editorStyle = {
    padding: '16px 12px',
    border: `1px solid ${COLORS.black30}`,
    borderRadius: '8px',
    minHeight: '80px',
    background: 'white',
    color: COLORS.black90,
    ...(!isFocused && {
      '&:hover': {
        borderColor: COLORS.black60,
      },
    }),
    ...(isFocused && {
      border: '2px solid',
      borderColor: 'primary.main',
      padding: '15px 11px', // hack adjust to 2px border
    }),
    ...(error && {
      borderColor: COLORS.error,
      '&:hover': {
        borderColor: COLORS.error,
      },
      '&:focus': {
        borderColor: COLORS.error,
      },
      opacity: 1,
    }),
    ...(!viewMode &&
      !editorRef?.current?._editable && {
        opacity: 0.5,
      }),
  }

  return (
    <LexicalComposer initialConfig={initialConfig({ value, name, editorRef })}>
      <Flex position="relative">
        <RichTextPlugin
          contentEditable={
            !viewMode ? (
              // TODO: remove styling in index.css
              <Flex sx={editorStyle}>
                <ContentEditable />
              </Flex>
            ) : (
              <ContentEditable />
            )
          }
          placeholder={
            !viewMode && (
              <Typography
                sx={{
                  ...style.placeholder,
                  ...(error && { color: COLORS.error }),
                }}
              >
                {placeholder}
              </Typography>
            )
          }
        />
        <OnChangePlugin onChange={onChange} />
        <HistoryPlugin />
        <CodeHighlightPlugin />
        <ListPlugin />
        <LinkPlugin validateUrl={validateUrl} />
        <AutoLinkPlugin matchers={MATCHERS} />
        <AutoLinkOnPastePlugin />
        <ListMaxIndentLevelPlugin maxDepth={7} />
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        <ReactHookFormPlugin
          disabled={disabled || viewMode}
          isSubmitSuccessful={isSubmitSuccessful}
          value={value}
          editorRef={editorRef}
        />
        {error && (
          <Typography variant="caption" color="error.main" sx={{ p: '4px 0 0 12px' }}>
            Required
          </Typography>
        )}
      </Flex>
    </LexicalComposer>
  )
}

export default LexicalEditor

const style = {
  placeholder: {
    color: COLORS.black60,
    overflow: 'hidden',
    position: 'absolute',
    textOverflow: 'ellipsis',
    top: '18px',
    left: '12px',
    pointerEvents: 'none',
  },
}
