import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { useGetRegistry } from 'api/hooks/get'
import { usePatchRegistry } from 'api/hooks/patch'
import Flex from 'components/common/Flex'
import { useAuth } from 'hooks/useAuth'
import { Select } from 'inputs/ControlledInputs'
import { useForm } from 'react-hook-form'
import { getObjectClone } from 'utils'

const SupplyClaimModal = ({ category, name, quantity, numClaimed, totalClaimed, claimLimit }) => {
  const fetchRegistry = useGetRegistry()
  const { mutateAsync: updateRegistry } = usePatchRegistry()
  const { user } = useAuth()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm({
    defaultValues: {
      quantity: claimLimit === '1' ? (!numClaimed ? 1 : 0) : numClaimed || 1,
    },
  })

  const onSubmit = async ({ quantity }) => {
    const updatedEntries = getObjectClone(fetchRegistry.data?.entries[0])

    if (quantity === 0) {
      delete updatedEntries[category][name].claims[user.id]
    } else {
      updatedEntries[category][name].claims[user.id] = {
        quantity: +quantity,
      }
    }

    await updateRegistry({ entries: [updatedEntries] })
  }

  return (
    <Flex component="form" gap="16px" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" alignSelf="center">
        {!numClaimed ? `Claim ${name}` : `Edit ${name} claim`}
      </Typography>

      {claimLimit === 'unlimited' && (
        <Select
          disabled={isSubmitting}
          name="quantity"
          label="Quantity"
          control={control}
          options={[
            ...Array(
              !numClaimed ? quantity - totalClaimed : quantity - totalClaimed + (numClaimed + 1)
            ).keys(),
          ].map((number) => ({
            label: number + (!numClaimed ? 1 : 0),
            value: number + (!numClaimed ? 1 : 0),
          }))}
        />
      )}

      <Typography>
        {claimLimit === '1'
          ? `Are you sure you want to ${!numClaimed ? 'claim' : 'unclaim'} ${name}?`
          : `Are you sure you want update your ${name} claims to ${watch('quantity')}?`}
      </Typography>

      <LoadingButton
        type="submit"
        sx={{ alignSelf: 'flex-end' }}
        onClick={handleSubmit}
        loading={isSubmitting}
      >
        Save
      </LoadingButton>
    </Flex>
  )
}

export default SupplyClaimModal
