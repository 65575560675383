import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import COLORS from 'assets/colors'
import Flex from 'components/common/Flex'
import { useCallback, useState } from 'react'

const useHandleConfirm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [confirmCallback, setConfirmCallback] = useState(null)

  const openModal = useCallback((callback) => {
    setConfirmCallback(() => callback)
    setIsModalOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
    setConfirmCallback(null)
  }, [])

  const handleConfirm = useCallback(() => {
    if (confirmCallback) {
      confirmCallback()
    }
    closeModal()
  }, [confirmCallback, closeModal])

  const modalContent = (
    <Modal open={isModalOpen} onClose={closeModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          borderRadius: '16px',
          border: `1px solid ${COLORS.black20}`,
          boxShadow: `1px 1px 4px ${COLORS.black60}`,
          p: '32px',
        }}
      >
        <Typography variant="h6" component="div" sx={{ marginBottom: 2 }}>
          Confirm Action
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: 2 }}>
          Are you sure you want to proceed?
        </Typography>
        <Flex row ml="8px" gap="24px">
          <Button onClick={handleConfirm}>Confirm</Button>
          <Button onClick={closeModal}>Cancel</Button>
        </Flex>
      </Box>
    </Modal>
  )

  return { openModal, closeModal, modalContent }
}

export default useHandleConfirm
