import { LoadingButton } from '@mui/lab'
import { Alert, Typography } from '@mui/material'
import Flex from 'components/common/Flex'
import { TextField } from 'inputs/ControlledInputs'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { formatPhoneNumber } from 'utils'

export const Login = () => {
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      phoneNumber: '',
    },
  })

  const onSubmit = async ({ phoneNumber }) => {
    try {
      const response = await fetch('/send-notification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phoneNumber: 1 + formatPhoneNumber(phoneNumber),
        }),
      })

      response.ok ? (
        navigate('/verify', {
          replace: true,
          state: {
            phonenumber: formatPhoneNumber(phoneNumber),
            rawNumber: phoneNumber,
          },
        })
      ) : (
        <Alert severity="error">{`Bad Response from number submit: ${response.ok}`}</Alert>
      )
    } catch (e) {
      return <Alert severity="error">Error in phone number submission</Alert>
    }
  }

  return (
    <Flex card component="form" sx={style.container} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5">Login</Typography>
      <TextField
        control={control}
        name="phoneNumber"
        placeholder="Please enter in a phone number"
        autoFocus
        disabled={isSubmitting}
        rules={{ required: 'Required' }}
        format="phoneNumber"
      />

      <LoadingButton type="submit" color="primary" onClick={handleSubmit} loading={isSubmitting}>
        Send code
      </LoadingButton>
    </Flex>
  )
}

export default Login

export const style = {
  container: {
    width: '100%',
    maxWidth: '400px',
    alignSelf: 'center',
    margin: 'auto 0',
    gap: '12px',
    p: ['12px 16px', '24px 32px'],
  },
}
