import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  LinearProgress,
  Typography,
} from '@mui/material'
import { useGetRegistry } from 'api/hooks/get'
import { AvatarUser } from 'components/common/Avatar'
import EllipsisMenu from 'components/common/EllipsisMenu'
import Flex from 'components/common/Flex'
import { useEvent } from 'contexts/EventContext'
import { useModal } from 'contexts/ModalContext'
import { useAuth } from 'hooks/useAuth'
import SupplyAddEditItemModal from './SupplyAddEditItemModal'
import SupplyClaimModal from './SupplyClaimModal'
import SupplyDeleteModal from './SupplyDeleteModal'
import SupplyEditCategoryModal from './SupplyEditCategoryModal'
import SupplyEditProgressModal from './SupplyEditProgressModal'

const getQuantitySum = (claims) =>
  Object.values(claims).reduce((sum, { quantity }) => sum + quantity, 0)

const Supply = ({ category, name, quantity, claims, alert, claimLimit, createdBy }) => {
  const { isHost } = useEvent()
  const { user } = useAuth()
  const { showModal } = useModal()

  const isOwner = createdBy === user.id || isHost
  const totalClaimed = getQuantitySum(claims)
  const numClaimed = claims[user.id]?.quantity
  const canBeClaimed = !numClaimed && quantity - totalClaimed > 0
  const disableExpandProps = totalClaimed === 0 && {
    expanded: false,
    onChange: () => {},
  }

  const items = [
    {
      name: 'Edit item',
      onClick: () =>
        showModal(
          <SupplyAddEditItemModal
            category={category}
            name={name}
            quantity={quantity}
            alert={alert}
            claimLimit={claimLimit}
            claims={claims}
            totalClaimed={totalClaimed}
          />
        ),
    },
    {
      name: 'Edit claims',
      onClick: () =>
        showModal(
          <SupplyEditProgressModal
            category={category}
            name={name}
            quantity={quantity}
            claims={claims}
          />
        ),
    },
    {
      name: 'Delete item',
      onClick: () => showModal(<SupplyDeleteModal category={category} name={name} />),
    },
  ]

  return (
    <Accordion
      sx={{ pl: ['4px', '8px'], position: 'relative' }}
      key={`${name}_${totalClaimed}`}
      {...disableExpandProps}
    >
      <AccordionSummary
        expandIcon={
          !isOwner &&
          totalClaimed > 0 && (
            <ExpandMoreIcon
              sx={{
                fontSize: 14,
                color: 'text.secondary',
              }}
            />
          )
        }
      >
        <Flex row justifyContent="space-between" width="100%">
          <Flex row gap="8px">
            <Typography fontSize={['14px', '16px']}>{name}</Typography>
            {alert && canBeClaimed && (
              <PriorityHighIcon sx={{ color: 'warning.main' }} fontSize="14px" />
            )}
            {numClaimed && <CheckIcon sx={{ color: 'success.main', fontSize: '14px' }} />}
          </Flex>
          <Flex row justifyContent="flex-end" position="absolute" right={0}>
            {(canBeClaimed || numClaimed) && (
              <Button
                variant="text"
                onClick={(e) => {
                  e.stopPropagation()
                  showModal(
                    <SupplyClaimModal
                      category={category}
                      name={name}
                      quantity={quantity}
                      numClaimed={numClaimed}
                      totalClaimed={totalClaimed}
                      claimLimit={claimLimit}
                    />
                  )
                }}
              >
                <Typography textAlign="right" fontSize={[13, 14]}>
                  {!numClaimed ? 'Claim' : 'Edit'}
                </Typography>
              </Button>
            )}
            <Typography color="text.secondary" fontSize={[13, 14]} width="40px" textAlign="right">
              {`${totalClaimed} / ${quantity}`}
            </Typography>
            {isOwner ? (
              <EllipsisMenu items={items} sx={{ fontSize: '14px', ml: '8px' }} />
            ) : (
              // spacing for when EllipsisMenu is hidden
              <Box sx={{ width: '22px' }} />
            )}
          </Flex>
        </Flex>
      </AccordionSummary>
      <AccordionDetails sx={{ pb: '8px' }}>
        <Flex gap="2px">
          {Object.entries(claims).map(([userId, { quantity }]) => {
            return (
              <AvatarUser
                userId={userId}
                showName
                fontSize={[13, 14]}
                subtitle={`Claimed (${quantity})`}
                key={userId}
              />
            )
          })}
        </Flex>
      </AccordionDetails>
    </Accordion>
  )
}

const Supplies = () => {
  const { user } = useAuth()
  const { isHost } = useEvent()
  const { showModal } = useModal()
  const fetchRegistry = useGetRegistry()
  const registry = fetchRegistry.data.entries[0]

  return (
    <Flex>
      <Button
        sx={{ alignSelf: 'flex-end' }}
        onClick={() => showModal(<SupplyAddEditItemModal />)}
        startIcon={<AddIcon />}
      >
        Add item
      </Button>
      {Object.entries(registry)
        .sort(([, a], [, b]) => {
          const aClaims = Object.values(a).reduce(
            (sum, { claims }) => sum + getQuantitySum(claims),
            0
          )
          const bClaims = Object.values(b).reduce(
            (sum, { claims }) => sum + getQuantitySum(claims),
            0
          )
          return aClaims - bClaims
        })
        .map(([category, items]) => {
          const itemsQuantity = getQuantitySum(items)
          const itemClaimsQuantity = Object.values(items).reduce((itemSum, { claims }) => {
            return itemSum + getQuantitySum(claims)
          }, 0)
          const progress = Math.round((itemClaimsQuantity / itemsQuantity) * 100)
          const hasItems = Object.keys(items).length > 0

          return (
            <Accordion defaultExpanded key={category}>
              <AccordionSummary
                expandIcon={hasItems && !isHost && <ExpandMoreIcon sx={{ fontSize: 14 }} />}
              >
                <Flex row justifyContent="space-between" width="100%" pr={!isHost && '8px'}>
                  <Flex row gap="2px">
                    <Typography variant="h6">{category}</Typography>
                    <Button
                      variant="text"
                      sx={{ fontSize: '12px' }}
                      onClick={(e) => {
                        e.stopPropagation()
                        showModal(<SupplyAddEditItemModal category={category} />)
                      }}
                    >
                      +
                    </Button>
                  </Flex>
                  <Flex row width={['50%', '60%']} justifyContent="flex-end">
                    {hasItems && (
                      <>
                        <LinearProgress value={progress} />
                        <Typography width="45px" textAlign="right" variant="subtitle1">
                          {itemClaimsQuantity} / {itemsQuantity}
                        </Typography>
                      </>
                    )}
                    {isHost && (
                      <EllipsisMenu
                        items={[
                          {
                            name: 'Edit category',
                            onClick: () =>
                              showModal(<SupplyEditCategoryModal category={category} />),
                          },
                          {
                            name: 'Delete category',
                            onClick: () => showModal(<SupplyDeleteModal category={category} />),
                          },
                        ]}
                        sx={{ fontSize: '14px', ml: '8px' }}
                      />
                    )}
                  </Flex>
                </Flex>
              </AccordionSummary>
              <AccordionDetails>
                {Object.entries(items)
                  .sort(([nameA, a], [nameB, b]) => {
                    const aClaimedByMe = a.claims[user.id]
                    const bClaimedByMe = b.claims[user.id]

                    const aClaimable = getQuantitySum(a.claims) < a.quantity && !aClaimedByMe
                    const bClaimable = getQuantitySum(b.claims) < b.quantity && !bClaimedByMe

                    if (aClaimable && !bClaimable) return -1
                    if (!aClaimable && bClaimable) return 1

                    if (aClaimedByMe && !bClaimedByMe) return -1
                    if (!aClaimedByMe && bClaimedByMe) return 1

                    return nameA.localeCompare(nameB)
                  })
                  .map(([name, item]) => {
                    return <Supply key={name} category={category} name={name} {...item} />
                  })}
              </AccordionDetails>
            </Accordion>
          )
        })}
    </Flex>
  )
}

export default Supplies
