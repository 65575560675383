import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import Flex from 'components/common/Flex'
import { useAuth } from 'hooks/useAuth'
import { TextField } from 'inputs/ControlledInputs'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { handleLoginNavigation } from 'utils'
import { style } from './Login'

const SignUp = () => {
  const { createNewUser } = useAuth()
  const { state } = useLocation()
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      firstname: '',
      lastName: '',
      userName: '',
      email: '',
    },
  })

  const onSubmit = async ({ firstName, lastName }) => {
    try {
      await createNewUser({
        firstName,
        lastName,
        phoneNumber: state.phoneNumber,
      })

      handleLoginNavigation(navigate)
    } catch (e) {
      console.error('Error in handleUserNameSubmit:', e)
    }
  }

  return (
    <Flex card sx={style.container} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5">Create account</Typography>
      <Flex row gap="4px" alignItems="flex-start">
        <TextField
          control={control}
          name="firstName"
          placeholder="First name"
          autoFocus
          disabled={isSubmitting}
          rules={{ required: 'Required' }}
          format="firstName"
        />

        <TextField
          control={control}
          name="lastName"
          placeholder="Last name"
          disabled={isSubmitting}
          rules={{ required: 'Required' }}
          format="lastName"
        />
      </Flex>
      <LoadingButton type="submit" color="primary" onClick={handleSubmit} loading={isSubmitting}>
        Sign up
      </LoadingButton>
    </Flex>
  )
}

export default SignUp
