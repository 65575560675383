import Flex from 'components/common/Flex'
import { useOutlet, useParams } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'

export const Layout = () => {
  const outlet = useOutlet()
  const { eventId } = useParams()

  return (
    <Flex minHeight="100vh" width="100%" backgroundColor="#F8F8F8">
      <Header />
      <Flex
        maxWidth={!eventId ? '1000px' : '100%'}
        width="100%"
        margin="0 auto"
        padding={!eventId ? ['16px', '16px 32px'] : []}
        flex="1"
      >
        {outlet}
      </Flex>
      <Footer />
    </Flex>
  )
}
