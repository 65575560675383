import { Stack } from '@mui/material'

const Flex = ({ children, row, card, ...props }) => {
  const rowProps = row ? { flexDirection: 'row', alignItems: 'center' } : {}
  const cardProps = card
    ? {
        backgroundColor: 'white',
        borderRadius: '16px',
        border: '1px solid',
        borderColor: 'divider',
        padding: ['12px 18px', '16px 24px'],
      }
    : {}

  return (
    <Stack {...rowProps} {...cardProps} {...props}>
      {children}
    </Stack>
  )
}

export default Flex
