import { createContext, useContext, useState } from 'react'
import Snackbar from '@mui/material/Snackbar'

const SnackbarContext = createContext()

export const SnackbarProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')

  const openSnackbar = (msg) => {
    setMessage(msg)
    setOpen(true)
  }

  const closeSnackbar = () => {
    setOpen(false)
  }

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      {children}
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        message={message}
        sx={{ width: '240px', margin: 'auto' }}
      />
    </SnackbarContext.Provider>
  )
}

export const useSnackbar = () => useContext(SnackbarContext)
