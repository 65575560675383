import { LoadingButton } from '@mui/lab'
import { Divider, FormHelperText, Typography } from '@mui/material'
import { useGetRegistry } from 'api/hooks/get'
import { usePatchRegistry } from 'api/hooks/patch'
import { AvatarUser } from 'components/common/Avatar'
import Flex from 'components/common/Flex'
import { useEvent } from 'contexts/EventContext'
import { Select } from 'inputs/ControlledInputs'
import { useForm } from 'react-hook-form'
import { getObjectClone } from 'utils'

const SupplyEditProgressModal = ({ category, name, quantity, claims }) => {
  const { fetchRsvps } = useEvent()
  const fetchRegistry = useGetRegistry()
  const { mutateAsync: updateRegistry } = usePatchRegistry()
  const rsvps = fetchRsvps.data.filter(({ attending }) => attending === 'YES')

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      ...Object.fromEntries(rsvps.map(({ user_id }) => [user_id, claims[user_id]?.quantity ?? 0])),
    },
  })

  const validateGroup = () => {
    const newQuantity = Object.values(watch()).reduce((acc, curr) => acc + curr, 0)
    newQuantity > quantity
      ? setError('quantity', { message: 'Max quantity exceeded' })
      : clearErrors('quantity')
  }

  const onSubmit = async (data) => {
    const updatedEntries = getObjectClone(fetchRegistry.data.entries[0])

    const updatedClaims = Object.fromEntries(
      Object.entries(data)
        .filter(([_, quantity]) => quantity !== 0)
        .map(([userId, quantity]) => [userId, { quantity }])
    )
    updatedEntries[category][name].claims = updatedClaims

    await updateRegistry({ entries: [updatedEntries] })
  }

  return (
    <Flex component="form" gap="16px" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" alignSelf="center">
        Edit {name} progress
      </Typography>
      <Typography>Max quantity: {quantity}</Typography>
      <Divider />
      <Flex>
        <Flex sx={styles.claims}>
          {rsvps?.map(({ user_id }) => {
            const options = [...Array(quantity + 1).keys()].map((number) => ({
              label: number,
              value: number,
            }))

            return (
              <Flex row key={user_id} justifyContent="space-between">
                <AvatarUser userId={user_id} showName />
                <Select
                  disabled={isSubmitting}
                  name={user_id}
                  control={control}
                  options={options}
                  size="small"
                  rules={{ validate: validateGroup }}
                />
              </Flex>
            )
          })}
        </Flex>
        {errors.quantity && <FormHelperText error>{errors.quantity.message}</FormHelperText>}
      </Flex>

      <LoadingButton
        type="submit"
        sx={{ alignSelf: 'flex-end' }}
        onClick={handleSubmit}
        loading={isSubmitting}
      >
        Save
      </LoadingButton>
    </Flex>
  )
}

export default SupplyEditProgressModal

const styles = {
  claims: {
    overflowY: 'auto',
    maxHeight: '30vh',
  },
}
