import { CalendarToday, Check } from '@mui/icons-material'
import { Checkbox, Divider, Typography } from '@mui/material'
import { usePatchTask } from 'api/hooks/patch'
import COLORS from 'assets/colors'
import Flex from 'components/common/Flex'
import { useEvent } from 'contexts/EventContext'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'

const AttendeeTask = ({ task, isCompleted }) => {
  const { user } = useAuth()
  const [checked, setChecked] = useState(isCompleted)
  const { mutate: patchTask } = usePatchTask()

  const handleChange = () => {
    setChecked(!checked)
    const completionDateTime = !isCompleted ? new Date().toUTCString() : null

    patchTask({
      taskPayload: {
        assignees: [
          {
            user_id: user.id,
            is_completed: !isCompleted,
            reminder_sent: !!task.reminder_sent,
            completion_date_time: completionDateTime,
          },
        ],
        respond_by: task.respond_by,
      },
      task: {
        ...task,
        assignees: [
          {
            user_id: user.id,
            is_completed: !isCompleted,
            completion_date_time: completionDateTime,
          },
        ],
      },
      isCompleted: !isCompleted,
    })
  }

  const completionDate = task.assignees.find(
    ({ user_id }) => user_id === user.id
  )?.completion_date_time

  return (
    <Flex row card sx={{ ...style.task, ...(isCompleted && style.completedTask) }}>
      <Checkbox name={task.id} checked={checked} value={checked} onChange={handleChange} />
      <Flex>
        <Typography
          sx={{
            wordBreak: 'break-word',
            ...(isCompleted && style.completedTaskMessage),
          }}
        >
          {task.message}
        </Typography>
        <Flex row gap="12px">
          {task.respond_by && (
            <Flex row sx={style.taskDate}>
              <CalendarToday sx={{ fontSize: '12px' }} />
              <Typography variant="caption">{convertUtcToDate(task.respond_by)}</Typography>
            </Flex>
          )}
          {isCompleted && (
            <Flex row sx={style.taskDate}>
              <Check sx={{ fontSize: '12px' }} />
              <Typography variant="caption">
                {completionDate ? convertUtcToDate(completionDate) : 'Completed by host'}
              </Typography>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

const AttendeeTasks = () => {
  const { fetchAttendeeIncompletedTasks, fetchAttendeeCompletedTasks } = useEvent()

  return (
    <>
      {fetchAttendeeIncompletedTasks.data.length === 0 ? (
        <Typography color="text.secondary">You have no open tasks!</Typography>
      ) : (
        <Flex gap="4px">
          {fetchAttendeeIncompletedTasks?.data.map((task) => {
            return <AttendeeTask key={`incompleted-${task.id}`} task={task} />
          })}
        </Flex>
      )}

      {fetchAttendeeCompletedTasks.data.length > 0 && (
        <>
          <Divider />
          <Flex gap="4px">
            {fetchAttendeeCompletedTasks.data.map((task) => {
              return <AttendeeTask key={`completed-${task.id}`} task={task} isCompleted />
            })}
          </Flex>
        </>
      )}
    </>
  )
}

export default AttendeeTasks

export const style = {
  task: {
    gap: ['4px', '8px'],
    padding: ['6px 8px 6px 6px', '9px 12px'],
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS.black10,
    },
  },
  completedTask: {
    backgroundColor: COLORS.black10,
  },
  completedTaskMessage: {
    textDecoration: 'line-through',
    color: 'text.secondary',
  },
  taskDate: {
    gap: '4px',
    fontSize: '13px',
    color: 'text.secondary',
  },
}

export const convertUtcToDate = (date) => {
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  }).format(date ? new Date(date) : new Date())
}
