import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import Flex from 'components/common/Flex'
import Loading from 'components/common/Loading'
import { useEvent } from 'contexts/EventContext'
import { useModal } from 'contexts/ModalContext'
import PollCard from './PollCard'
import PollCreateModal from './PollCreateModal'

const Polls = () => {
  const { showModal } = useModal()
  const { fetchPolls } = useEvent()

  if (fetchPolls.isLoading) return <Loading size="32px" />

  return (
    <Flex gap="16px">
      <Button
        onClick={() => showModal(<PollCreateModal />)}
        sx={{ alignSelf: 'flex-end' }}
        startIcon={<AddIcon />}
      >
        Create poll
      </Button>
      {fetchPolls.data.length > 0 && (
        <Flex gap="16px">
          {fetchPolls.data.map((entry, idx) => (
            <PollCard key={entry.id} data={entry} idx={idx} />
          ))}
        </Flex>
      )}
    </Flex>
  )
}

export default Polls
