import { Box, Button, Typography } from '@mui/material'
import Flex from 'components/common/Flex'
import { useNavigate } from 'react-router-dom'

const Landing = () => {
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        margin: 'auto',
        maxWidth: '600px',
      }}
    >
      <Typography component="h1" variant="h2" align="center" color="text.primary" gutterBottom>
        Collaborative event planning made easy
      </Typography>
      <Typography variant="h5" align="center" color="text.secondary" paragraph>
        Nothing’s more exciting than when your event makes it out of the group chat. Whether you’re
        planning a multi-day ski trip or the next Dreamforce, simplify event organization and make
        every detail a shared success with GroupUp.
      </Typography>
      <Flex row gap="16px" justifyContent="center" mt="32px">
        <Button onClick={() => navigate('/login')}>Plan an event</Button>
        <Button variant="outlined" onClick={() => navigate('/login')}>
          Explore features
        </Button>
      </Flex>
    </Box>
  )
}

export default Landing
