import {
  Checkbox as CheckboxMUI,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as SelectMUI,
  Switch as SwitchMUI,
  TextField as TextFieldMUI,
  Typography,
} from '@mui/material'
import { DatePicker as DatePickerMUI, TimePicker as TimePickerMUI } from '@mui/x-date-pickers'
import { Controller } from 'react-hook-form'
import { formatPhoneNumber } from 'utils'
import LexicalEditor, { getEditorStateString } from './LexicalEditor'

const getTextFieldFormatProps = (format) => {
  switch (format) {
    case 'phoneNumber':
      return {
        formatValue: (value) => formatPhoneNumber(value),
        formatOnChange: (value) => value.replace(/[^\d]/g, '').slice(0, 10),
        formatRules: {
          minLength: { value: 10, message: 'Phone number must be 10 digits' },
        },
      }
    case 'verifyNumber':
      return {
        formatOnChange: (value) => value.replace(/[^\d]/g, '').slice(0, 6),
      }
    default: {
      return {}
    }
  }
}

export const TextField = ({ name, type, control, rules, defaultValue, format, ...props }) => {
  const { formatValue, formatOnChange, formatRules } = getTextFieldFormatProps(format)
  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules, ...formatRules }}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextFieldMUI
            {...field}
            {...props}
            defaultValue={defaultValue}
            type={type}
            value={formatValue ? formatValue(field.value) : field.value}
            onChange={(e) => {
              field.onChange(formatOnChange ? formatOnChange(e.target.value) : e.target.value)
            }}
            error={!!error}
            helperText={error ? error.message : ''}
          />
        )
      }}
    />
  )
}

export const DatePicker = ({ name, control, rules, label, disabled, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        return (
          <DatePickerMUI
            {...field}
            {...props}
            label={label}
            disabled={disabled}
            format="LL"
            slotProps={{
              textField: {
                fullWidth: true,
                variant: 'outlined',
                error: !!error,
                helperText: error ? error.message : '',
              },
            }}
          />
        )
      }}
    />
  )
}

export const TimePicker = ({ name, control, rules, label, disabled }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <TimePickerMUI
          {...field}
          label={label}
          disabled={disabled}
          slotProps={{
            textField: {
              fullWidth: true,
              variant: 'outlined',
              error: !!error,
              helperText: error ? error.message : '',
            },
          }}
        />
      )}
    />
  )
}

export const TextEditor = ({
  name,
  placeholder,
  control,
  disabled,
  rules: { required } = {},
  editorRef, // using Editor(Ref) since value doesn't convert to EditorState unless edited
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: {
          ...(required && {
            required: () => {
              const editorStateString = getEditorStateString(editorRef.current.getEditorState())
              return editorStateString !== '' || required
            },
          }),
        },
      }}
      render={({ field, fieldState: { error }, formState: { isSubmitSuccessful } }) => {
        return (
          <LexicalEditor
            {...field}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            isSubmitSuccessful={isSubmitSuccessful}
            editorRef={editorRef}
            error={error}
          />
        )
      }}
    />
  )
}

export const Checkbox = ({ name, control, disabled }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CheckboxMUI
          {...field}
          disabled={disabled}
          checked={field.value}
          onChange={(e) => field.onChange(e.target.checked)}
        />
      )}
    />
  )
}

export const Switch = ({ name, control, rules, label, disabled }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        return (
          <FormControlLabel
            control={<SwitchMUI {...field} checked={field.value} disabled={disabled} />}
            label={<Typography variant="subtitle1">{label}</Typography>}
          />
        )
      }}
    />
  )
}

export const Select = ({ name, control, rules, label, disabled, options, size }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormControl error={error} size={size}>
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            <SelectMUI {...field} label={label} disabled={disabled}>
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </SelectMUI>
            <FormHelperText>{error ? error.message : ''}</FormHelperText>
          </FormControl>
        )
      }}
    />
  )
}
