import { Layout } from 'components/Layout'
import { EventProvider } from 'contexts/EventContext'
import { ModalProvider } from 'contexts/ModalContext'
import EventCreateEdit from 'pages/EventCreateEdit'
import { Route, Routes } from 'react-router-dom'
import { ProtectedLayout } from './components/ProtectedLayout'
import { UnprotectedLayout } from './components/UnprotectedLayout'
import ErrorPage from './pages/ErrorPage'
import Event from './pages/Event/index'
import Events from './pages/Events/index'
import Landing from './pages/Landing/index'
import PrivacyPolicyPage from './pages/Legal/PrivacyPoilcy'
import TermsAndConditionsPage from './pages/Legal/TermsAndConditions'
import Login from './pages/Login/Login'
import SignUp from './pages/Login/SignUp'
import Verify from './pages/Login/Verify'
import ProfilePage from './pages/Profile/index'

const App = () => {
  const basename = process.env.REACT_APP_BASENAME || null

  return (
    <Routes basename={basename} element={<Layout />}>
      <Route path="/" element={<Layout />}>
        <Route element={<UnprotectedLayout />}>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/signup" element={<SignUp />} />
        </Route>

        <Route path="/" element={<ProtectedLayout />}>
          <Route path="events" element={<Events />} />
          <Route
            path="Profile"
            element={
              <ModalProvider>
                <ProfilePage />
              </ModalProvider>
            }
          />
        </Route>

        <Route path="events">
          <Route
            path=":eventId/*"
            element={
              <EventProvider>
                <ModalProvider>
                  <Routes>
                    <Route path="" element={<Event />} />
                    <Route path="edit" element={<EventCreateEdit />} />
                  </Routes>
                </ModalProvider>
              </EventProvider>
            }
          ></Route>
          <Route path="create" element={<EventCreateEdit />} />
        </Route>

        <Route path="terms-conditions" element={<TermsAndConditionsPage />} />
        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  )
}
export default App
