import { Add, Clear } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, MenuItem, Select, TextField, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import { DatePicker } from '@mui/x-date-pickers'
import { usePostPoll } from 'api/hooks/post'
import COLORS from 'assets/colors'
import Flex from 'components/common/Flex'
import dayjs from 'dayjs'
import { useState } from 'react'
import { getUTCDateTime } from 'utils'

const PollCreateModal = () => {
  const { mutate: createPoll, isPending } = usePostPoll()
  const [title, setTitle] = useState('')
  const [choices, setChoices] = useState([{ name: '', responses: null, type: 'TEXT' }])
  const [allowMultiple, setAllowMultiple] = useState(false)
  const [allowCustomEntry, setAllowCustomEntry] = useState(false)
  const [hideResults, setHideResults] = useState('NEVER')
  const [respondBy, setRespondBy] = useState(false)

  const initialFormValues = {
    due_date: {},
    due_time: { value: dayjs() },
  }
  const [formValues, setFormValues] = useState(initialFormValues)

  const handleTitleChange = (e) => {
    setTitle(e.target.value)
  }

  const handleChoiceChange = (e, index) => {
    const newChoices = [...choices]
    newChoices[index] = { ...newChoices[index], name: e.target.value }
    setChoices(newChoices)
  }

  const handleAddChoice = () => {
    setChoices([...choices, { name: '', responses: [], type: 'TEXT' }])
  }

  const handleRemoveChoice = (index) => {
    const newChoices = choices.filter((_, i) => i !== index)
    setChoices(newChoices)
  }

  const handleAddRespondByDate = () => {
    setRespondBy((prev) => !prev)
  }

  const handleHideResultsChange = (e) => {
    setHideResults(e.target.value)
  }

  const handleSubmit = () => {
    const entries = choices.map((choice) => ({
      name: choice.name,
      responses: null,
      type: 'TEXT',
    }))

    const dateTime = getUTCDateTime(formValues.due_date.value, formValues.due_time.value)

    const payload = {
      type: allowMultiple ? 'SELECT_MULTIPLE' : 'SELECT_SINGLE',
      message: title,
      entries,
      hide_results: hideResults,
      reminder_date_time: null,
      allow_custom_entry: allowCustomEntry,
      ...(dateTime !== null && {
        respond_by: dateTime,
      }),
    }

    createPoll(payload)
  }

  return (
    <Flex component="form" gap="16px">
      <Flex spacing={2}>
        <Typography variant="h6" sx={{ justifyContent: 'center', display: 'flex' }}>
          Create a poll
        </Typography>
        <Box>
          <TextField
            fullWidth
            label="Poll title"
            variant="outlined"
            value={title}
            onChange={handleTitleChange}
            disabled={isPending}
          />
        </Box>

        {choices.map((choice, index) => (
          <TextField
            key={index}
            label={`Choice ${index + 1}`}
            size="small"
            value={choice.name}
            onChange={(e) => handleChoiceChange(e, index)}
            disabled={isPending}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => handleRemoveChoice(index)} disabled={isPending}>
                  <Clear sx={styles.icon} />
                </IconButton>
              ),
            }}
          />
        ))}

        <Button
          variant="outlined"
          onClick={handleAddChoice}
          disabled={isPending}
          sx={{ alignSelf: 'flex-end' }}
        >
          Add Choice
        </Button>

        <Box>
          <Flex row>
            <Checkbox
              name="AllowMultipleChoice"
              checked={allowMultiple}
              value={allowMultiple}
              onChange={() => setAllowMultiple((prev) => !prev)}
            />
            <Typography>Allow multiple selections</Typography>
          </Flex>

          <Flex row>
            <Checkbox
              name="AllowCustomEntry"
              checked={allowCustomEntry}
              value={allowCustomEntry}
              onChange={() => setAllowCustomEntry((prev) => !prev)}
            />
            <Typography>Allow attendees to add own choices</Typography>
          </Flex>
        </Box>

        <Flex gap="8px">
          {respondBy ? (
            <Flex row gap="8px">
              <DatePicker
                name="due_date"
                label="Due date"
                value={formValues.due_date?.value && dayjs(formValues.due_date.value)}
                onChange={(val) => {
                  setFormValues({
                    ...formValues,
                    due_date: { value: dayjs(val) },
                  })
                }}
                format="LL"
                sx={{ width: '100%' }}
              />
              <IconButton disabled={isPending} onClick={handleAddRespondByDate}>
                <Clear sx={styles.icon} />
              </IconButton>
            </Flex>
          ) : (
            <Button variant="outlined" onClick={handleAddRespondByDate} startIcon={<Add />}>
              Respond by date
            </Button>
          )}
        </Flex>

        <Flex sx={styles.hideResults}>
          <Typography>Hide results?</Typography>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              labelId="demo-simple-select-label"
              value={hideResults}
              onChange={handleHideResultsChange}
              disabled={isPending}
            >
              <MenuItem value="ALWAYS">Always</MenuItem>
              <MenuItem value="BEFORE_RESPONSE">Before response</MenuItem>
              <MenuItem value="NEVER">Never</MenuItem>
            </Select>
          </FormControl>
        </Flex>
      </Flex>
      <LoadingButton onClick={handleSubmit} loading={isPending} sx={{ alignSelf: 'flex-end' }}>
        Create
      </LoadingButton>
    </Flex>
  )
}

export default PollCreateModal

export const styles = {
  icon: {
    fontSize: '18px',
    cursor: 'pointer',
    color: COLORS.black50,
    '&:hover': {
      color: 'text.primary',
    },
  },
  hideResults: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}
