import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import Flex from 'components/common/Flex'
import { useState } from 'react'
import { useEvent } from '../../../contexts/EventContext'
import AttendeeTasks from './AttendeeTasks'
import HostTasks from './HostTasks'

const Tasks = () => {
  const { isHost } = useEvent()
  const [isManageMode, setIsManageMode] = useState(isHost)
  const isFetchingTasks = useIsFetching({ queryKey: ['tasks'] })
  const isMutatingTasks = useIsMutating()

  return (
    <Flex row={false} card={false} gap="16px">
      {isHost && (
        <ToggleButtonGroup
          sx={{ alignSelf: 'center' }}
          size="small"
          color="primary"
          value={isManageMode}
          exclusive
          onChange={() => setIsManageMode(!isManageMode)}
          disabled={!!isFetchingTasks || !!isMutatingTasks}
        >
          <ToggleButton value={false}>Your tasks</ToggleButton>
          <ToggleButton value={true}>Manage all tasks</ToggleButton>
        </ToggleButtonGroup>
      )}
      {isManageMode ? <HostTasks /> : <AttendeeTasks />}
    </Flex>
  )
}

export default Tasks
