import { Check, Clear } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Radio,
  TextField,
  Typography,
} from '@mui/material'

import { usePatchPoll } from 'api/hooks/patch'
import { AvatarGroup, AvatarUser } from 'components/common/Avatar'
import Flex from 'components/common/Flex'
import React, { useState } from 'react'
import { styles } from './PollCreateModal'

const PollAnswerModal = ({ pollData, currentUser }) => {
  const { mutate: answerPoll, isPending } = usePatchPoll()

  const { id, entries, message, type, allow_custom_entry, hide_results } = pollData

  const initialFormValues = {
    ...entries.reduce((acc, { name, responses }) => {
      acc[name] = {
        value: responses && responses.some((response) => response.user === currentUser.id),
      }
      return acc
    }, {}),
  }
  const [formValues, setFormValues] = useState(initialFormValues)
  const [choices, setChoices] = useState(entries)
  const [addChoiceMode, setAddChoiceMode] = useState(false)
  const [newChoice, setNewChoice] = useState({
    name: '',
    responses: [],
    type: 'TEXT',
  })

  const handleAddChoice = (bool) => {
    if (!bool) setNewChoice({ name: '', responses: [], type: 'TEXT' })
    setAddChoiceMode(bool)
    setNewChoice({ name: '', responses: [], type: 'TEXT' })
  }

  const handleChoiceChange = (e) => {
    setNewChoice({ name: e.target.value, responses: [], type: 'TEXT' })
  }

  const handleSubmitNewChoice = (choice) => {
    // clear other choices if poll is SELECT SINGLE and allows custom fields
    const clearedFormValues = Object.keys(formValues).reduce((acc, key) => {
      acc[key] = { value: false }
      return acc
    }, {})

    const updatedFormValues = type === 'SELECT_SINGLE' ? clearedFormValues : formValues

    setFormValues({
      ...updatedFormValues,
      [choice.name]: { value: true },
    })
    setChoices([...choices, choice])
    setSortedChoices([...choices, choice])
    setAddChoiceMode(false)
  }

  const handleChange = (e) => {
    const { name, checked } = e.target

    if (type === 'SELECT_SINGLE') {
      const updatedFormValues = {}
      for (const entryName in formValues) {
        updatedFormValues[entryName] = { value: false }
      }
      updatedFormValues[name] = { value: checked }
      setFormValues(updatedFormValues)
    } else {
      setFormValues({
        ...formValues,
        [name]: { value: checked },
      })
    }
  }

  const handleSubmit = () => {
    const entryResponse = {
      user: currentUser.id,
      respondedAt: new Date().toISOString(),
      remindCount: 1,
    }

    const updatedEntries = choices.map((entry) => {
      const isChecked = formValues[entry.name]?.value
      const responses = entry.responses || []
      const userResponseIndex = responses.findIndex((response) => response.user === currentUser.id)

      if (isChecked) {
        if (userResponseIndex === -1) {
          responses.push(entryResponse)
        }
      } else {
        if (userResponseIndex !== -1) {
          responses.splice(userResponseIndex, 1)
        }
      }

      return {
        ...entry,
        responses,
      }
    })

    answerPoll({
      pollPayload: {
        pollId: id,
        payload: {
          entries: updatedEntries,
        },
      },
    })
  }

  // Sort the choices based on the number of responses each choice has
  const [sortedChoices, setSortedChoices] = useState(
    [...choices].sort((a, b) => (b.responses?.length || 0) - (a.responses?.length || 0))
  )

  return (
    <Flex component="form" gap="16px">
      <Typography variant="h6" alignSelf="center">
        Vote
      </Typography>
      <Typography variant="h5">{message}</Typography>
      <Flex>
        <Typography variant="body2">
          {type === 'SELECT_MULTIPLE' && 'Select one or more options'}
        </Typography>
        <List>
          {sortedChoices.map((entry, idx) => (
            <React.Fragment key={entry.name}>
              <Flex flexDirection="row" justifyContent="space-around" alignItems="center">
                <ListItem
                  role={undefined}
                  onClick={handleChange}
                  disablePadding
                  sx={{ p: '4px 0' }}
                >
                  <FormControlLabel
                    control={type === 'SELECT_SINGLE' ? <Radio /> : <Checkbox />}
                    name={entry.name}
                    checked={formValues[entry.name].value || false}
                    value={[entry.name].value}
                    onChange={handleChange}
                    tabIndex={-1}
                  />
                  <ListItemText primary={`${entry.name}`} />
                </ListItem>
                {hide_results !== 'ALWAYS' && (
                  <AvatarGroup max={4} small>
                    {entry.responses?.map(({ user }) => {
                      return <AvatarUser showTooltip key={user} userId={user} />
                    })}
                  </AvatarGroup>
                )}
              </Flex>
              <Divider sx={{ mb: idx === sortedChoices.length - 1 ? '8px' : 0 }} />
            </React.Fragment>
          ))}
        </List>
        {addChoiceMode && (
          <TextField
            label="Add choice"
            value={newChoice.name}
            onChange={handleChoiceChange}
            sx={{ alignSelf: 'flex-start' }}
            size="small"
            fullWidth
            InputProps={{
              endAdornment: (
                <Flex row>
                  <IconButton onClick={() => handleSubmitNewChoice(newChoice)} disabled={isPending}>
                    <Check sx={styles.icon} />
                  </IconButton>
                  <IconButton onClick={() => handleAddChoice(false)} disabled={isPending}>
                    <Clear sx={styles.icon} onClick={() => handleAddChoice(false)} />
                  </IconButton>
                </Flex>
              ),
            }}
          />
        )}
        {allow_custom_entry && !addChoiceMode && (
          <Button
            variant="outlined"
            onClick={() => handleAddChoice(true)}
            sx={{ alignSelf: 'flex-start' }}
          >
            <Typography>Add choice</Typography>
          </Button>
        )}
      </Flex>

      <LoadingButton onClick={handleSubmit} loading={isPending} sx={{ alignSelf: 'flex-end' }}>
        Save
      </LoadingButton>
    </Flex>
  )
}

export default PollAnswerModal
