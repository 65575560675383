import EditIcon from '@mui/icons-material/Edit'
import { Avatar, Typography } from '@mui/material'
import { useGetUser } from 'api/hooks/get'
import Flex from 'components/common/Flex'
import { useModal } from 'contexts/ModalContext'
import { useAuth } from 'hooks/useAuth'
import { calculateJoiningTime } from 'utils'
import EditProfile from './EditProfile'

const ProfilePage = () => {
  const { user } = useAuth()
  const { showModal } = useModal()
  const fetchUser = useGetUser()
  const { first_name, last_name, created_at } = fetchUser.data.id ? fetchUser.data : user

  return (
    <Flex sx={style.container}>
      <Avatar sx={{ width: 180, height: 180 }} />
      <Flex gap="4px" position="relative" alignItems="center">
        <Typography variant="h5" fontWeight="bold">
          {`${first_name} ${last_name}`}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {calculateJoiningTime(created_at)}
        </Typography>
        <EditIcon sx={style.editIcon} onClick={() => showModal(<EditProfile />)} />
      </Flex>
    </Flex>
  )
}

export default ProfilePage

const style = {
  container: {
    width: '100%',
    m: '64px auto',
    alignItems: 'center',
    gap: '16px',
  },
  editIcon: {
    position: 'absolute',
    top: '-16px',
    right: '-24px',
    fontSize: '20px',
    cursor: 'pointer',
    color: 'primary.main',
    '&:hover': { opacity: '80%' },
  },
}
