import { Tab, Tabs, Typography } from '@mui/material'
import { AvatarUser } from 'components/common/Avatar'
import Flex from 'components/common/Flex'
import { useState } from 'react'

const PollResponseModal = ({ message, choices, index }) => {
  const [tabValue, setTabValue] = useState(index)

  return (
    <Flex height="500px" gap="16px">
      <Typography variant="h6" textAlign="center">
        {message}
      </Typography>
      <Flex>
        <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
          {choices.map((choice, idx) => (
            <Tab
              key={choice.name}
              label={`${choice.name} (${choice.responses.length || 0})`}
              value={idx}
            />
          ))}
        </Tabs>
        <Flex gap="4px" pt="16px" overflow="auto">
          {choices[tabValue].responses.map(({ user }) => {
            return <AvatarUser key={user} showName userId={user} />
          })}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PollResponseModal
