import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { useGetRegistry } from 'api/hooks/get'
import { usePatchRegistry } from 'api/hooks/patch'
import Flex from 'components/common/Flex'
import { TextField } from 'inputs/ControlledInputs'
import { useForm } from 'react-hook-form'
import { getObjectClone } from 'utils'

const SupplyEditCategoryModal = ({ category }) => {
  const fetchRegistry = useGetRegistry()
  const { mutateAsync: updateRegistry } = usePatchRegistry()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      name: category ?? '',
    },
  })

  const entries = fetchRegistry.data.entries[0]

  const onSubmit = async ({ name }) => {
    const updatedEntries = getObjectClone(entries)
    const categoryItems = updatedEntries[category]
    updatedEntries[name] = categoryItems
    delete updatedEntries[category]

    await updateRegistry({ entries: [updatedEntries] })
  }

  return (
    <Flex component="form" gap="16px" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6">Edit category</Typography>
      <TextField
        control={control}
        name="name"
        label="Name"
        disabled={isSubmitting}
        rules={{
          required: 'Required',
          validate: (value) => {
            return !entries[value] || 'Category already exists'
          },
        }}
      />
      <LoadingButton
        type="submit"
        sx={{ alignSelf: 'flex-end' }}
        onClick={handleSubmit}
        loading={isSubmitting}
      >
        Save
      </LoadingButton>
    </Flex>
  )
}

export default SupplyEditCategoryModal
