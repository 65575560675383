const COLORS = {
  primary: '#3f52e3',
  secondary: '#ec9b3b',
  error: '#f75940',
  success: '#65A487',
  warning: '#E09F4F',
  black100: '#262626',
  black90: '#303030',
  black80: '#474747',
  black70: '#5E5E5E',
  black60: '#6E6E6E',
  black50: '#949494',
  black40: '#A8A8A8',
  black30: '#C2C2C2',
  black25: '#D5D5D5',
  black20: '#E8E8E8',
  black15: '#F1F1F1',
  black10: '#FAFAFA',
  black0: '#FFFFFF',
}

export default COLORS
