import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const ErrorPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Redirect to the last visited page after 5 seconds
      navigate(-1)
    }, 5000)

    // Cleanup the timeout when the component unmounts
    return () => clearTimeout(timeoutId)
  }, [navigate])

  return (
    <div>
      <h1>404 Not Found. Oops!</h1>
      <p>Sending you back to the last page. Hold on...</p>
    </div>
  )
}

export default ErrorPage
