import { Typography } from '@mui/material'
import Flex from 'components/common/Flex'
import Loading from 'components/common/Loading'
import { useEvent } from 'contexts/EventContext'
import InfiniteScroll from 'react-infinite-scroll-component'
import Comment from './Comment'
import CommentPost from './CommentPost'

const Comments = () => {
  const { fetchComments } = useEvent()

  if (fetchComments.isLoading) return <Loading size="32px" />

  return (
    <Flex gap="16px">
      <Typography variant="h5">Comments</Typography>
      <CommentPost />
      {fetchComments.data.length > 0 && (
        <InfiniteScroll
          dataLength={fetchComments.data.length}
          next={fetchComments.fetchNextPage}
          hasMore={fetchComments.hasNextPage}
          loader={<Loading size="32px" margin="16px" />}
        >
          <Flex gap="32px" mb="16px">
            {fetchComments.data.map((comment) => (
              <Comment key={comment.id} comment={comment} />
            ))}
          </Flex>
        </InfiniteScroll>
      )}
    </Flex>
  )
}

export default Comments
