import { Navigate, useOutlet } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
// checks for persisting user and redirects to events page if session still active
export const UnprotectedLayout = () => {
  const { user } = useAuth()
  const outlet = useOutlet()

  if (user) {
    return <Navigate to="/events" />
  }

  return outlet
}
