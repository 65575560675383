import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useDeletePoll } from 'api/hooks/delete'
import COLORS from 'assets/colors'
import EllipsisMenu from 'components/common/EllipsisMenu'
import Flex from 'components/common/Flex'
import { useEvent } from 'contexts/EventContext'
import { useModal } from 'contexts/ModalContext'
import { useAuth } from 'hooks/useAuth'
import { formatDate } from 'utils'
import PollAnswerModal from './PollAnswerModal'
import PollContent from './PollContent'
import PollEditModal from './PollEditModal'

const PollCard = ({ data }) => {
  const { user: currentUser } = useAuth()
  const { showModal } = useModal()
  const { hide_results, message, respond_by, hasVoted } = data
  const { isHost, fetchRsvps } = useEvent()
  const { mutate: deletePoll } = useDeletePoll()

  const isPollComplete = new Date().toISOString() >= respond_by

  const needsBlur =
    (!hasVoted && hide_results === 'BEFORE_RESPONSE' && !isPollComplete) ||
    (hide_results === 'ALWAYS' && !isPollComplete && !isHost)

  const handleDeletePoll = (pollId) => {
    deletePoll(pollId)
  }

  const ellipsisMenuItems = [
    {
      name: 'Edit',
      onClick: () => showModal(<PollEditModal pollData={data} />),
    },
    {
      name: 'Delete',
      confirm: true, // brings up confirmation modal
      onClick: () => handleDeletePoll(data.id),
    },
  ]

  return (
    <Flex card gap="16px" sx={{ position: 'relative' }}>
      <Box position="absolute" top={['8px', '16px']} right={['8px', '12px']}>
        {isHost && <EllipsisMenu items={ellipsisMenuItems} />}
      </Box>

      <Typography variant="h6" align="center" p="0 8px">
        {message}
      </Typography>

      {needsBlur ? (
        <Flex row sx={styles.blurBox}>
          <Typography sx={styles.foregroundText}>
            {hide_results === 'ALWAYS'
              ? 'Poll results are visible only to the host'
              : 'Poll results are hidden, vote to see results'}
          </Typography>
        </Flex>
      ) : (
        <PollContent pollData={data} rsvps={fetchRsvps} currentUser={currentUser} />
      )}

      {respond_by && (
        <Typography variant="body2" alignSelf="flex-end">
          {isPollComplete
            ? `Poll completed ${formatDate(respond_by, 'PollCard')}`
            : `Respond by ${formatDate(respond_by, 'PollCard')}`}
        </Typography>
      )}

      {!isPollComplete && (
        <Button
          variant={hasVoted ? 'outlined' : 'contained'}
          sx={{ width: ['100%', '45%'], alignSelf: 'flex-end' }}
          onClick={() => showModal(<PollAnswerModal pollData={data} currentUser={currentUser} />)}
        >
          {!hasVoted ? 'Vote' : 'Change vote'}
        </Button>
      )}
    </Flex>
  )
}

const styles = {
  blurBox: {
    position: 'relative',
    justifyContent: 'center',
    py: 16,
    backgroundColor: `${COLORS.black60}`,
  },
  foregroundText: {
    position: 'absolute',
    zIndex: 1,
    color: 'white',
    p: '16px',
  },
}

export default PollCard
