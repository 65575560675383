import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import COLORS from 'assets/colors'
import Flex from 'components/common/Flex'
import { useState } from 'react'
import useHandleConfirm from '../../hooks/useHandleConfirm'

const EllipsisMenu = ({ items, sx }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = !!anchorEl
  const { openModal, modalContent } = useHandleConfirm()

  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleItemClick = (item) => {
    item.confirm ? openModal(item.onClick) : item.onClick()
    handleClose()
  }

  const menuItems = items.map((item, index) => (
    <MenuItem
      key={index}
      onClick={() => handleItemClick(item)}
      sx={{ p: '4px 16px', minHeight: 0 }}
    >
      {item.name}
    </MenuItem>
  ))

  return (
    <Flex row onClick={(e) => e.stopPropagation()}>
      <MoreVertIcon
        sx={{
          fontSize: '24px',
          cursor: 'pointer',
          color: COLORS.black50,
          '&:hover': { color: 'text.primary' },
          ...sx,
        }}
        onClick={handleClick}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ mt: '8px' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {menuItems}
      </Menu>
      {modalContent}
    </Flex>
  )
}

export default EllipsisMenu
