import { Chip, Link } from '@mui/material'
import { useGetHostedEvents, useGetRsvpEvents } from 'api/hooks/get'
import Flex from 'components/common/Flex'
import Loading from 'components/common/Loading'
import moment from 'moment'
import { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link as RouterLink } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import EventCard from './EventCard'

const Events = () => {
  const { user } = useAuth()
  const [hosting, setHosting] = useState(true)
  const [atttending, setAttending] = useState(true)
  const [past, setPast] = useState(false)

  const attendingFilter = {
    ...(atttending &&
      !hosting && {
        exclude_hosted_by_me: true,
      }),
  }

  const pastFilter = {
    ...{
      exclude_hosted_by_me: false,
      'start_date_time.lt': moment().format('YYYY-MM-DDTHH:mmZ'),
    },
  }

  const toggleHostingButton = () => {
    setHosting(!hosting)
    setPast(false)
  }

  const toggleAttendingButton = () => {
    setAttending(!atttending)
    setPast(false)
  }

  // clicking on Past bubble will show all events RSVP'd or hosted, as long as the start_date_time of the event is prior to today's date
  const togglePastButton = () => {
    setPast(!past)
    setHosting(past)
    setAttending(past)
  }

  const enableGetHostedEvents = hosting && !atttending

  const getHostedEvents = useGetHostedEvents(attendingFilter, enableGetHostedEvents)
  const getRsvps = useGetRsvpEvents(attendingFilter, !enableGetHostedEvents)
  const getPastEvents = useGetRsvpEvents(pastFilter, !enableGetHostedEvents) //includes both hosting and attending
  const isLoading = getHostedEvents.isLoading || getRsvps.isLoading || getPastEvents.isLoading

  const getEvents = () => {
    if (past) {
      return getPastEvents
    } else if ((hosting && atttending) || (!hosting && atttending)) {
      return getRsvps
    } else if (hosting && !atttending) {
      return getHostedEvents
    } else {
      return null // No events when both hosting and atttending are false
    }
  }

  const events = getEvents()

  return (
    <Flex gap="16px">
      <Flex
        row
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Flex row gap="8px">
          <Chip
            label="Hosting"
            variant={hosting ? 'outlined' : 'filled'}
            onClick={toggleHostingButton}
          />
          <Chip
            label="Attending"
            variant={atttending ? 'outlined' : 'filled'}
            onClick={toggleAttendingButton}
          />
        </Flex>
        <Chip label="Past" variant={past ? 'outlined' : 'filled'} onClick={togglePastButton} />
      </Flex>
      {isLoading ? (
        <Loading margin="32px" />
      ) : (
        <>
          {events?.data.length ? (
            <InfiniteScroll
              dataLength={events.data.length}
              next={events.fetchNextPage}
              hasMore={events.hasNextPage}
              loader={<Loading margin="16px" size="32px" />}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              {events.data.map((eventItem, index) => (
                <EventCard key={`event-${index}`} eventData={eventItem} user_id={user.id} />
              ))}
            </InfiniteScroll>
          ) : (
            <h4>
              You have no events yet.{' '}
              <Link component={RouterLink} to="/events/create">
                Plan
              </Link>{' '}
              or join one!
            </h4>
          )}
        </>
      )}
    </Flex>
  )
}

export default Events
