import Flex from 'components/common/Flex'
import GuestList from './GuestList/GuestList'

const EventContent = ({ ContentComponent }) => {
  return (
    <Flex
      row
      alignSelf="center"
      alignItems="flex-start"
      gap="16px"
      p={['16px', '16px 32px']}
      width="100%"
      maxWidth="1000px"
    >
      <Flex card flex={1}>
        {ContentComponent}
      </Flex>
      <GuestList />
    </Flex>
  )
}

export default EventContent
