import { Divider } from '@mui/material'
import Flex from 'components/common/Flex'
import { useEvent } from 'contexts/EventContext'
import { useAuth } from 'hooks/useAuth'
import LexicalEditor from 'inputs/LexicalEditor'
import Comments from './Comments'

const About = () => {
  const { user } = useAuth()
  const { fetchEvent, fetchRsvps } = useEvent()
  const rsvp = fetchRsvps.data?.find(({ user_id }) => user_id === user?.id)
  const isRsvp = rsvp?.attending === 'YES'

  return (
    <Flex gap="16px">
      {!!JSON.parse(fetchEvent.data.description)?.root.children[0].children.length && (
        <>
          <LexicalEditor value={fetchEvent.data.description} viewMode={true} />
          {isRsvp && <Divider />}
        </>
      )}
      {isRsvp && <Comments />}
    </Flex>
  )
}

export default About
