import { Link, Typography } from '@mui/material/'
import { Link as RouterLink } from 'react-router-dom'
import Flex from './common/Flex'

const Footer = () => {
  return (
    <Flex row justifyContent="center" gap="4px 16px" p="32px 24px" flexWrap="wrap">
      <Typography fontWeight="700">&copy; {new Date().getFullYear()} GroupUp</Typography>
      <Typography>
        <Link component={RouterLink} to="/terms-conditions">
          Terms & Conditions
        </Link>
      </Typography>
      <Typography>
        <Link component={RouterLink} to="/privacy-policy">
          Privacy Policy
        </Link>
      </Typography>
      <Typography>
        <Link href="mailto:groupupfeedback@gmail.com">Contact Us</Link>
      </Typography>
    </Flex>
  )
}

export default Footer
