import { LoadingButton } from '@mui/lab'
import { usePostComment } from 'api/hooks/post'
import Flex from 'components/common/Flex'
import { useEvent } from 'contexts/EventContext'
import { Switch, TextEditor } from 'inputs/ControlledInputs'
import { getEditorStateString } from 'inputs/LexicalEditor'
import { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'

const CommentPost = () => {
  const editorRef = useRef(null)
  const { isHost } = useEvent()
  const { mutateAsync: postComment } = usePostComment()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      body: null,
      notify_guests: false,
    },
  })

  const onSubmit = async ({ body, notify_guests }) => {
    await postComment({
      body: JSON.stringify(body),
      notify_guests: notify_guests,
      sms_body: notify_guests ? getEditorStateString(body) : undefined,
    })
  }

  // reset after isSubmitSuccessful
  useEffect(() => reset(), [isSubmitSuccessful, reset])

  return (
    <Flex component="form" onSubmit={handleSubmit(onSubmit)} gap="8px" m="0 8px">
      <TextEditor
        control={control}
        name="body"
        placeholder="Add a comment"
        disabled={isSubmitting}
        rules={{ required: 'Required' }}
        editorRef={editorRef}
      />
      <Flex row justifyContent="flex-end" gap="4px">
        {isHost && (
          <Switch
            control={control}
            name="notify_guests"
            disabled={isSubmitting}
            label="Text Blast"
          />
        )}
        <LoadingButton
          type="submit"
          loading={isSubmitting}
          sx={{ alignSelf: 'flex-end', margin: 0 }}
        >
          Submit
        </LoadingButton>
      </Flex>
    </Flex>
  )
}

export default CommentPost
