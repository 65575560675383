import { Typography } from '@mui/material'
import Flex from 'components/common/Flex'

const TermsAndConditionsPage = () => {
  return (
    <Flex gap="16px">
      <Typography variant="h4">Messaging Terms & Conditions</Typography>
      <Typography variant="body1">
        By using the service, you are consenting to be contacted by GroupUp via text message, SMS
        and/or MMS message for marketing, solicitation, informational or another purpose. You agree
        to receive recurring automated reminders and other information pertaining events that you’ve
        created or are subscribed to from GroupUp including SMS and MMS that may be sent to the
        mobile telephone number you provided when signing up. Consent to receive automated marketing
        text messages is not a condition of employment. Message and Data rates may apply. Message
        frequency will vary. GroupUp reserves the right to alter the frequency of messages sent at
        any time, which may increase or decrease the total number of messages that we send. We
        reserve the right, at our sole discretion, to change or modify portions of these Terms of
        Service at any time. If a change is made, we will indicate at the top of this page the date
        these Terms of Service were last revised.
      </Typography>

      <Typography variant="h4">How to Quit</Typography>
      <Typography variant="body1">
        At any time, text the keyword STOP to the number we texted you on to cancel. After texting
        STOP to the number we texted you on you will receive one additional message confirming your
        request to opt-out. You acknowledge that our text message platform may not recognize and
        respond to STOP requests that do not include the STOP keyword and agree that GroupUp and its
        service providers will have no liability for failing to honor such requests.
      </Typography>

      <Typography variant="h4">How to Get Help</Typography>
      <Typography variant="body1">
        At any time, text the keyword HELP to the number we texted you from to receive information
        about the GroupUp SMS program.
      </Typography>

      <Typography variant="h4">Contact</Typography>
      <Typography variant="body1">
        GroupUp may be contacted at the following email address: groupupfeedback@gmail.com
      </Typography>
    </Flex>
  )
}

export default TermsAndConditionsPage
