import { Divider, Typography } from '@mui/material'
import { AvatarGroup, AvatarUser } from 'components/common/Avatar'
import Loading from 'components/common/Loading'
import { useEvent } from 'contexts/EventContext'
import { useModal } from 'contexts/ModalContext'
import { useAuth } from 'hooks/useAuth'

import Flex from 'components/common/Flex'
import { rsvpMap } from 'pages/Event'
import GuestListModal from './GuestListModal'

const GuestList = () => {
  const { user } = useAuth()
  const { showModal } = useModal()
  const { fetchEvent, fetchRsvps, fetchUsers } = useEvent()

  const handleOpenGuestListModal = (e, tab) => {
    if (!user) return
    e.stopPropagation()

    showModal(<GuestListModal tab={tab} />)
  }

  return (
    <Flex
      card
      gap="8px"
      onClick={(e) => handleOpenGuestListModal(e, 'YES')}
      sx={{ display: ['none', 'flex'], cursor: 'pointer' }}
      width="350px"
    >
      <Typography variant="h6" fontWeight="bold">
        Guest list
      </Typography>

      {fetchRsvps.data.length !== fetchRsvps.count || fetchUsers.isLoading ? (
        <Loading size="32px" margin="16px" />
      ) : (
        <Flex mt="8px" gap="8px">
          {['YES', 'MAYBE', 'NO'].map((rsvpStatus) => {
            return (
              <Flex row key={rsvpStatus} justifyContent="space-between">
                <Flex row gap="8px" onClick={(e) => handleOpenGuestListModal(e, rsvpStatus)}>
                  {rsvpMap[rsvpStatus].icon}
                  <Typography>{rsvpMap[rsvpStatus].copy}</Typography>
                </Flex>
                <Typography color="text.secondary" pr="4px">
                  {fetchEvent.data[`rsvp_${rsvpStatus.toLowerCase()}_count`] || 0}
                </Typography>
              </Flex>
            )
          })}
          <Divider sx={{ m: '8px 0' }} />
          <AvatarGroup max={9} sx={{ alignSelf: 'flex-start' }}>
            {fetchRsvps.data
              .filter(({ attending }) => attending === 'YES')
              .map(({ user_id }) => (
                <AvatarUser key={user_id} userId={user_id} showTooltip />
              ))}
          </AvatarGroup>
        </Flex>
      )}
    </Flex>
  )
}

export default GuestList
