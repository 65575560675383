import { Box, Fade, Modal, keyframes } from '@mui/material'
import { createContext, useContext, useState } from 'react'

const ModalContext = createContext()

export const useModal = () => useContext(ModalContext)

const slideDown = keyframes`
  from {
    transform: translate(-50%, -60%);
  }
  to {
    transform: translate(-50%, -50%);
  }
`
const slideUp = keyframes`
  from {
    transform: translate(-50%, -50%);
  }
  to {
    transform: translate(-50%, -60%);
  }
`

export const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null)
  const [isExiting, setIsExiting] = useState(false)
  const showModal = (content) => setModalContent(() => content)

  const hideModal = () => {
    setIsExiting(true)
    setTimeout(() => {
      setModalContent(null)
      setIsExiting(false)
    }, 300)
  }

  const open = !!modalContent

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      {modalContent && (
        <Modal open={open} onClose={hideModal}>
          <Fade in={open && !isExiting} timeout={{ enter: 300, exit: 300 }}>
            <Box
              sx={{
                ...style,
                animation: open && !isExiting ? `${slideDown} 0.3s` : `${slideUp} 0.3s`,
              }}
            >
              {modalContent}
            </Box>
          </Fade>
        </Modal>
      )}
    </ModalContext.Provider>
  )
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'background.paper',
  borderRadius: '24px',
  border: `1px solid divider`,
  boxShadow: '0 0.5rem 1rem rgba(0,0,0,.5)',
  padding: '24px 32px',
  width: ['90vw', '100%'],
  maxWidth: '450px',
  maxHeight: '90vh',
  overflowY: 'auto',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}
