import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import Flex from 'components/common/Flex'
import { TextField } from 'inputs/ControlledInputs'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import { style } from './Login'

export const Verify = () => {
  const { state } = useLocation()
  const { login } = useAuth()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError,
  } = useForm({
    defaultValues: {
      verifyNumber: '',
    },
  })

  const onSubmit = async ({ verifyNumber }) => {
    try {
      const response = await fetch('/verify-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          otp: verifyNumber,
          phoneNumber: 1 + state.phonenumber,
        }),
      })

      const verification = await response.json()

      verification.valid
        ? await login(
            JSON.stringify({
              status: response.ok,
              phoneNumber: 1 + state.rawNumber,
            })
          )
        : setError('verifyNumber', {
            type: 'manual',
            message: 'Verification failed. Please try again',
          })
    } catch (e) {
      console.error('Error in submitVerification:', e)
    }
  }

  return (
    <Flex card component="form" onSubmit={handleSubmit(onSubmit)} sx={style.container}>
      <Flex>
        <Typography variant="h5">Verify</Typography>
        <Typography variant="caption">Check your phone for a verification number!</Typography>
      </Flex>
      <TextField
        control={control}
        name="verifyNumber"
        placeholder="Verification Code"
        autoFocus
        disabled={isSubmitting}
        rules={{ required: 'Required' }}
        format="verifyNumber"
        autoComplete="one-time-code"
      />

      <LoadingButton type="submit" color="primary" onClick={handleSubmit} loading={isSubmitting}>
        Verify
      </LoadingButton>
    </Flex>
  )
}

export default Verify
