import { MenuItem, Select, Tab, Tabs, Typography } from '@mui/material'
import { usePatchRsvp } from 'api/hooks/patch'
import { AvatarUser } from 'components/common/Avatar'
import Flex from 'components/common/Flex'
import { useEvent } from 'contexts/EventContext'
import { rsvpMap } from 'pages/Event'
import { useState } from 'react'

const GuestListAttendee = ({ userId, rsvpStatus }) => {
  const { mutateAsync: patchRsvp, isPending } = usePatchRsvp()
  const { isHost } = useEvent()

  const handleChange = async (e) => {
    await patchRsvp({
      attending: e.target.value,
      party_size: 1,
      user_to_update: userId,
    })
  }

  return (
    <Flex row component="form" justifyContent="space-between" key={userId}>
      <AvatarUser showName={true} userId={userId} />
      {isHost && (
        <Select
          sx={{ width: '100px', height: '36px' }}
          value={rsvpStatus}
          onChange={handleChange}
          disabled={isPending}
        >
          <MenuItem value="YES">Yes</MenuItem>
          <MenuItem value="MAYBE">Maybe</MenuItem>
          <MenuItem value="NO">No</MenuItem>
        </Select>
      )}
    </Flex>
  )
}

const GuestListModal = ({ tab }) => {
  const [tabValue, setTabValue] = useState(tab || 'YES')
  const { fetchEvent, fetchRsvps } = useEvent()

  const rsvpsList = {
    YES: fetchRsvps.data.filter(({ attending }) => attending === 'YES'),
    MAYBE: fetchRsvps.data.filter(({ attending }) => attending === 'MAYBE'),
    NO: fetchRsvps.data.filter(({ attending }) => attending === 'NO'),
  }

  return (
    <Flex height="500px">
      <Typography variant="h6" alignSelf="center">
        Guest list
      </Typography>
      <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
        {['YES', 'MAYBE', 'NO'].map((rsvpStatus) => (
          <Tab
            key={rsvpStatus}
            label={`${rsvpMap[rsvpStatus].copy} (${
              fetchEvent.data[`rsvp_${rsvpStatus.toLowerCase()}_count`] || 0
            })`}
            value={rsvpStatus}
          />
        ))}
      </Tabs>
      <Flex maxHeight="430px" gap="4px" p="16px 0" overflow="auto">
        {rsvpsList[tabValue]?.map(({ user_id }) => {
          return <GuestListAttendee key={user_id} userId={user_id} rsvpStatus={tabValue} />
        })}
      </Flex>
    </Flex>
  )
}

export default GuestListModal
