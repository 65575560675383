import { usePostUser } from 'api/hooks/post'
import { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { handleLoginNavigation } from 'utils'
import { useLocalStorage } from './useLocalStorage'

const server = process.env.REACT_APP_SERVER

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const { mutateAsync: createUser } = usePostUser()
  // Initialize user state only once using useMemo
  const [user, setUser] = useLocalStorage('user')

  const createNewUser = async ({ userName, firstName, lastName, email, phoneNumber }) => {
    try {
      const userData = await createUser({
        phoneNumber,
        firstName,
        lastName,
        userName,
        email,
      })

      setUser(userData)

      return userData
    } catch (e) {
      return
    }
  }

  const getUserId = async (phoneNumber) => {
    try {
      const response = await fetch(`${server}/users/phone_number/${phoneNumber}`, {
        headers: {
          Authorization: getAuthToken('user_rsUckhccvY95cPGPiMn2sS'),
        },
      })
      const data = await response.text()

      return data
    } catch (e) {
      return
    }
  }

  const getUserData = async (userId) => {
    try {
      const response = await fetch(`${server}/users/${userId}`, {
        headers: {
          Authorization: getAuthToken(userId),
        },
      })
      const userData = await response.json()
      setUser(userData)
    } catch (e) {
      return
    }
  }

  const login = async (data) => {
    // get user_id if user data not found in cache
    if (!user) {
      const { phoneNumber } = JSON.parse(data)
      // try to retrieve user id
      const id = await getUserId(phoneNumber)
      // if user id not found, create new user
      // on success retrieve user data
      if (id) {
        await getUserData(id)
        // if coming from external event link redirect them there
        handleLoginNavigation(navigate)
      } else {
        navigate('/signup', {
          replace: true,
          state: {
            phoneNumber,
          },
        })
      }
    } else {
      return
    }
  }

  const logout = () => {
    setUser(null)
    navigate('/', { replace: true })
  }

  const refetchUser = async (userId) => {
    await getUserData(userId)
  }

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      refetchUser,
      createNewUser,
      isAdmin: [
        'user_6qKx4pEYKLHaj1aTF1PSoj',
        'user_EyWPpo3rnojWZSRd8qSwo',
        'user_k9GX8yeVJGP2MVVLeD53g5',
        'user_6944Fk23FCzegKRhmdq6G6',
      ].includes(user?.id),
    }),
    [user]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}

export const getAuthToken = (userId = 'user_rsUckhccvY95cPGPiMn2sS') =>
  'Basic ' + btoa(`${userId}:password`).toString('base64')
