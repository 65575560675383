import { Typography } from '@mui/material'
import Flex from 'components/common/Flex'

const PrivacyPoilcyPage = () => {
  return (
    <Flex gap="16px">
      <Typography variant="h3">GroupUp Privacy Policy</Typography>
      <Typography variant="h5">Information We Collect</Typography>
      <Typography variant="body1">
        We gather personal information provided by you when you interact with us, whether through
        registration, expressing interest in our products or Services, engaging in activities, or
        contacting us. The type of information collected depends on your interactions, choices, and
        use of features. This may encompass: <ul>App and Services usage</ul>
        <ul>Phone number</ul>
        <ul>Username that an individual may choose when establishing an account on our website</ul>
        <ul>Name</ul>
        It is imperative that all information you furnish is accurate, complete, and truthful. Any
        changes to this data should be promptly communicated to us.
      </Typography>
      <Typography variant="body1">
        Certain information, such as your Internet Protocol (IP) address, browser, and device
        characteristics, is automatically gathered when you use our Services. This data, while not
        revealing specific personal identity details, encompasses device and usage specifics like IP
        address, browser type, operating system, language preferences, referring URLs, device name,
        country, location, details about Service usage, and other technical data. Its primary use is
        to uphold the security and functionality of our Services, as well as for internal analytics
        and reporting. This information is obtained through technologies like cookies.
      </Typography>

      <Typography variant="body1">The data collected includes:</Typography>
      <Typography variant="body1">
        <ul>
          Log and Usage Data: This pertains to service-related, diagnostic, usage, and performance
          information logged by our servers during your use of our Services. It may encompass your
          IP address, device information, browser type, settings, activity in the Services, and
          device event details.
        </ul>
      </Typography>

      <Typography variant="h5">How We Use Your Information</Typography>
      <Typography variant="body1">
        We utilize personal information for various purposes, including providing, enhancing, and
        managing our Services, as well as ensuring security and compliance with legal requirements.
        Additionally, we may seek your consent for other processing activities.
      </Typography>
      <Typography variant="body1">
        Specifically, we use personal information to:
        <ul>
          Operate, secure, and enhance our Services, managing user accounts and facilitating account
          creation.
        </ul>
        <ul>Furnish details about our Services.</ul>
        <ul>
          Communicate with you regarding announcements, updates, security alerts, and support
          messages.
        </ul>
        <ul>Tailor your experience and communications based on your preferences and interests.</ul>
        <ul>
          Address your inquiries, requests, and feedback. Conduct research and development, aiming
          to improve our Services and introduce new offerings.
        </ul>
        Additionally, we may engage in marketing and advertising efforts with our partners, while
        ensuring your personal information is not sold. Furthermore, we may employ personal
        information to adhere to legal obligations and processes, respond to government requests,
        and comply with applicable laws. In matters of compliance, fraud prevention, and safety, we
        work to safeguard rights, privacy, and property, enforce Service terms and conditions, and
        combat fraudulent or unlawful activity. For research and development purposes, we may use
        de-identified information to evaluate Service effectiveness, enhance features, and analyze
        user behavior. This anonymized data may be shared with third parties for legitimate business
        purposes.
      </Typography>

      <Typography variant="h5">Information Sharing</Typography>
      <Typography variant="body1">
        We may share information as outlined here and with specific categories of third parties.
        These include vendors, consultants, and service providers who assist us in various
        capacities. We have contractual agreements with them to protect your information. They can
        only use it for the services we've instructed them to perform.
      </Typography>

      <Typography variant="body1">
        These are the categories of third parties we may share information with:
        <ul>Testing tools</ul>
        <ul>User account registration & authentication services</ul>
        <ul>Product engineering & design tools</ul>
        <ul>Data storage service providers</ul>
        <ul>Data analytics services</ul>
        <ul>Communication & collaboration tools</ul>
        <ul>Performance monitoring tools</ul>
      </Typography>

      <Typography variant="h5">How We Protect Information</Typography>
      <Typography variant="body1">
        We maintain reasonable administrative, technical, and physical safeguards designed to
        protect the personal information we maintain against accidental, unlawful, or unauthorized
        access, disclosure, alteration, use, loss, or destruction. However, we cannot guarantee that
        the safeguards we maintain will ensure the security of the personal information.
      </Typography>

      <Typography variant="h5">Data Retention</Typography>
      <Typography variant="body1">
        We will retain personal information only for as long as necessary to fulfill the purpose(s)
        for which the information was collected, depending on the purpose(s) for which the
        information was collected, the nature of the information, any contractual relationship that
        may govern the retention of the data, and our legal or regulatory obligations. We will then
        destroy the personal information or anonymize the information, in accordance with applicable
        law.
      </Typography>

      <Typography variant="h5">Minors</Typography>
      <Typography variant="body1">
        Our websites and services are not intended for individuals under 18 years of age, and
        individuals must be at least 18 years of age to register with our websites. We do not
        knowingly collect personal information from children under age 13 through our websites. If
        we learn that we have collected personal information from a child under the age of 13
        through our websites, we will delete that information
      </Typography>

      <Typography variant="h5">Changing or Deleting Your Personal Information</Typography>
      <Typography variant="body1">
        If you would at any time like to review or change the information in your account or
        terminate your account, you can:
        <ul>Contact us using the contact information provided. </ul>
        Upon your request to terminate your account, we will deactivate or delete your account and
        information from our active databases. However, we may retain some information in our files
        to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal
        terms, and/or comply with applicable legal requirements.
      </Typography>

      <Typography variant="h5">Your Choices</Typography>
      <Typography variant="body1">
        Opt out of marketing communications. You may respond "STOP" to any GroupUp text message to
        stop receiving all text messages from GroupUp. Opt out of receiving invitations from past
        hosts or mutuals. Past hosts and mutuals may send you invitations to other events via
        GroupUp, unless you have opted out of receiving future invitations from the host or mutual.
        Online tracking opt-out. There are a number of ways to opt out of having your online
        activity and device data collected through our Services, which we have summarized below:
        Blocking cookies in your browser. Most browsers let you remove or reject cookies. To do
        this, follow the instructions in your browser settings. Many browsers accept cookies by
        default until you change your settings. For more information about cookies, including how to
        see what cookies have been set on your device and how to manage and delete them, visit
        www.allaboutcookies.org. Using privacy plug-ins or browsers. You can block our Services from
        setting cookies by using a browser with privacy features, like Brave, or installing browser
        plugins like Privacy Badger, DuckDuckGo, Ghostery or uBlockOrigin, and configuring them to
        block cookies/trackers. Note that because these opt-out mechanisms are specific to the
        device or browser on which they are exercised, you will need to opt out on every browser and
        device that you use. If you have questions or comments about your privacy rights, you may
        email us at groupupfeedback@gmail.com.
      </Typography>

      <Typography variant="h5">Changes to our Privacy Notice</Typography>
      <Typography variant="body1">
        GroupUp reserves the right to change this Privacy Notice at any time. When we update this
        Privacy Notice, we will notify you of changes that are deemed material under applicable
        legal requirements by updating the date of this Privacy Notice and providing other
        notification as required by applicable law. We may also notify you of changes to the Privacy
        Notice in other ways, such as via email or other contact information you have provided.
      </Typography>

      <Typography variant="h5">How to Contact Us</Typography>
      <Typography variant="body1">
        You may contact us with questions, comments, or complaints about this Privacy Notice or our
        privacy practices, or if you wish to access, delete or correct your information. Our contact
        information is as follows:
        <ul>GroupUp Support</ul>
        <ul>groupupfeedback@gmail.com</ul>
      </Typography>
    </Flex>
  )
}

export default PrivacyPoilcyPage
