import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { useGetUser } from 'api/hooks/get'
import { usePatchUser } from 'api/hooks/patch'
import Flex from 'components/common/Flex'
import { useModal } from 'contexts/ModalContext'
import { TextField } from 'inputs/ControlledInputs'
import { useForm } from 'react-hook-form'

const EditProfile = () => {
  const fetchUser = useGetUser()
  const user = fetchUser.data
  const { hideModal } = useModal()
  const { mutateAsync: updateProfile } = usePatchUser()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
    },
  })

  const onSubmit = async ({ first_name, last_name }) => {
    await updateProfile({ first_name, last_name }, { onSuccess: () => hideModal() })
  }

  return (
    <Flex component="form" gap="16px" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5">Edit Profile</Typography>
      <Flex row gap="8px">
        <TextField
          control={control}
          name="first_name"
          placeholder="First name"
          disabled={isSubmitting}
          sx={{ flex: 1 }}
        />
        <TextField
          control={control}
          name="last_name"
          placeholder="Last name"
          disabled={isSubmitting}
          sx={{ flex: 1 }}
        />
      </Flex>
      <LoadingButton
        type="submit"
        sx={{ alignSelf: 'flex-end', width: '40%' }}
        onClick={handleSubmit}
        loading={isSubmitting}
      >
        Submit
      </LoadingButton>
    </Flex>
  )
}

export default EditProfile
