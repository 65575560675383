import { CssBaseline, ThemeProvider as MuiThemeProvider, createTheme, darken } from '@mui/material'
import COLORS from 'assets/colors'
import { createContext, useContext } from 'react'

const ThemeContext = createContext()
const BORDER_COLOR = COLORS.black20
const BORDER_STYLE = `1px solid ${BORDER_COLOR}`

export const useTheme = () => useContext(ThemeContext)

export const ThemeProvider = ({ children }) => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
    typography: {
      fontWeightMedium: '600',
      allVariants: { letterSpacing: '0em' },
      h3: {
        fontSize: '42px',
        fontWeight: 700,
        lineHeight: 1.2,
      },
      h4: {
        fontSize: '36px',
        fontWeight: 700,
        lineHeight: 1.2,
      },
      h5: {
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: 1.2,
      },
      h6: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: 1.2,
      },
      body2: {
        fontSize: '16px',
        color: COLORS.black60,
      },
      subtitle1: {
        fontSize: '14px',
        lineHeight: 1.5,
      },
      caption: {
        fontSize: '12px',
        lineHeight: 1.5,
      },
      fontFamily:
        'Source Sans Pro, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    },
    palette: {
      divider: BORDER_COLOR,
      primary: {
        main: COLORS.primary,
      },
      secondary: {
        main: COLORS.secondary,
      },
      error: {
        main: COLORS.error,
      },
      success: {
        main: COLORS.success,
      },
      warning: {
        main: COLORS.warning,
      },
      text: {
        primary: COLORS.black90,
        secondary: COLORS.black60,
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            transition: 'color 0.2s',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            transition: 'color 0.2s',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: 'none',
            color: 'black',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: COLORS.black60,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
            borderRadius: '8px',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: COLORS.black30,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: COLORS.black60,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: COLORS.primary,
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: COLORS.error,
            },
          },
        },
      },
      MuiLoadingButton: {
        defaultProps: {
          disableElevation: true,
          variant: 'contained',
        },
        root: {
          color: 'white',
        },
      },
      MuiSwitch: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: COLORS.black20,
          },
        },
      },
      MuiAccordion: {
        defaultProps: {
          elevation: 0,
          disableGutters: true,
        },
        styleOverrides: {
          root: {
            // top border disappears for expanded accordion by default for some reason
            '&:not(:last-child)': {
              borderBottom: BORDER_STYLE,
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: '0',
            margin: 0,
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '8px',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '8px',
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            transition: 'color 0s',
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          '*': {
            transition: 'border-color 0.2s, opacity 0.2s',
            WebkitTapHighlightColor: 'transparent',
          },
          '*:focus': {
            outline: 'none',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&:disabled': {
              opacity: '60%',
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            color: COLORS.black70,
            backgroundColor: COLORS.black20,
            fontWeight: 'bold',
            fontSize: '16px',
            width: '36px',
            height: '36px',
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: 'transparent',
              '& .MuiSvgIcon-root': {
                fill: darken(COLORS.primary, 0.125),
              },
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
        },
        styleOverrides: {
          text: {
            '&:hover': {
              backgroundColor: 'transparent',
              color: darken(COLORS.primary, 0.4),
            },
          },
          contained: {
            '&:hover': {
              backgroundColor: darken(COLORS.primary, 0.125),
            },
          },
          startIcon: {
            marginRight: '2px',
            '*:nth-of-type(1)': {
              fontSize: 'inherit',
            },
          },
          root: {
            fontSize: '14px',
            fontWeight: '700',
            height: '40px',
            minWidth: 0,
            borderRadius: '24px',
            textTransform: 'none',
            boxShadow: 'none',
            transition: 'background-color 0.2s',
            '&.Mui-disabled': {
              color: 'inherit',
              opacity: 0.5,
            },
            '&:hover': {
              boxShadow: 'none',
            },
          },
          defaultProps: {
            disableElevation: true,
          },
        },
        variants: [
          {
            props: { variant: 'delete' },
            style: {
              backgroundColor: 'red',
              color: 'white',
              '&:hover': {
                backgroundColor: 'darkred',
              },
            },
          },
        ],
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontSize: '1rem',
            '&:hover': {
              backgroundColor: COLORS.black30,
              color: 'black',
            },
          },
          outlined: {
            backgroundColor: COLORS.black70,
            color: 'white',
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: '12px',
            height: '36px',
            padding: '0 16px',
          },
        },
      },
      MuiTabs: {
        defaultProps: {
          variant: 'scrollable',
        },
        styleOverrides: {
          root: {
            padding: 0,
            minHeight: 0,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: '0',
            textTransform: 'none',
            minHeight: 0,
            fontSize: '15px',
            pl: '18px',
            pr: '18px',
            '&:hover': {
              color: COLORS.black90,
            },
            '&.Mui-selected:hover': {
              color: COLORS.primary,
            },
            transition: 'color 0.2s',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            boxShadow: '0 4px 12px rgba(0, 0, 0, .10)',
            borderRadius: '8px',
            border: BORDER_STYLE,
          },
        },
        defaultProps: {
          elevation: 0,
          autoFocus: false,
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            height: '6px',
            borderRadius: '16px',
            flex: 1,
          },
        },
        defaultProps: {
          variant: 'determinate',
        },
      },
    },
  })

  return (
    <ThemeContext.Provider>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
