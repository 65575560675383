import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { useGetRegistry } from 'api/hooks/get'
import { usePatchRegistry } from 'api/hooks/patch'
import Flex from 'components/common/Flex'
import { useForm } from 'react-hook-form'
import { getObjectClone } from 'utils'

const SupplyDeleteModal = ({ category, name }) => {
  const fetchRegistry = useGetRegistry()
  const { mutateAsync: updateRegistry } = usePatchRegistry()
  const isDeleteItem = !!name

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()

  const onSubmit = async () => {
    const updatedEntries = getObjectClone(fetchRegistry.data.entries[0])
    isDeleteItem ? delete updatedEntries[category][name] : delete updatedEntries[category]
    await updateRegistry({ entries: [updatedEntries] })
  }

  return (
    <Flex component="form" gap="16px" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" alignSelf="center">
        Delete {name ? 'item' : 'category'}
      </Typography>
      <Typography>
        Are you sure you want to delete {name ? `${name} from ${category}` : category}?
      </Typography>
      <LoadingButton
        type="submit"
        sx={{ alignSelf: 'flex-end' }}
        onClick={handleSubmit}
        loading={isSubmitting}
      >
        Delete
      </LoadingButton>
    </Flex>
  )
}

export default SupplyDeleteModal
