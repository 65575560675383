import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useModal } from 'contexts/ModalContext'
import { getAuthToken, useAuth } from 'hooks/useAuth'
import { useNavigate, useParams } from 'react-router-dom'

const server = process.env.REACT_APP_SERVER

export const usePostUser = () => {
  return useMutation({
    mutationFn: async ({ phoneNumber, firstName, lastName, userName, email }) => {
      const response = await fetch(`${server}/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken('user_rsUckhccvY95cPGPiMn2sS'),
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          username: userName,
          phone_number: phoneNumber,
          email,
        }),
      })

      return await response.json()
    },
  })
}

export const usePostRsvp = () => {
  const { user } = useAuth()
  const { eventId } = useParams()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (rsvp) => {
      const response = await fetch(`${server}/events/${eventId}/rsvp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
        body: JSON.stringify(rsvp),
      })

      return await response.json()
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.refetchQueries({ queryKey: ['rsvps', eventId] }),
        queryClient.refetchQueries({ queryKey: ['users', eventId] }),
        queryClient.refetchQueries({ queryKey: ['events', eventId] }),
      ])
    },
  })
}

export const usePostEvent = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async (event) => {
      const response = await fetch(`${server}/events`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
        body: JSON.stringify(event),
      })

      return await response.json()
    },
    onSuccess: ({ id }) => {
      navigate(`/events/${id}`)
    },
  })
}

export const usePostComment = () => {
  const { user } = useAuth()
  const { eventId } = useParams()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (comment) => {
      const response = await fetch(`${server}/events/${eventId}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
        body: JSON.stringify(comment),
      })
      return await response.json()
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ['comments', eventId],
      })
    },
  })
}

export const usePostRegistry = () => {
  const { user } = useAuth()
  const { eventId } = useParams()
  return useMutation({
    mutationFn: async ({ entries }) => {
      const response = await fetch(`${server}/events/${eventId}/registry`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
        body: JSON.stringify({ entries }),
      })
      return await response.json()
    },
  })
}

export const usePostTask = () => {
  const { hideModal } = useModal()
  const { user } = useAuth()
  const { eventId } = useParams()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (task) => {
      const response = await fetch(`${server}/events/${eventId}/tasks`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
        body: JSON.stringify(task),
      })
      if (!response.ok) {
        const errorData = await response.json()
        throw new Error(errorData.message || 'Failed to post task')
      }
      return await response.json()
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ['tasks', { eventId }],
      })
      hideModal()
    },
  })
}

export const usePostPoll = () => {
  const { hideModal } = useModal()
  const { user } = useAuth()
  const { eventId } = useParams()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (poll) => {
      const response = await fetch(`${server}/events/${eventId}/polls`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
        body: JSON.stringify(poll),
      })
      return await response.json()
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ['polls', eventId],
      })
      hideModal()
    },
  })
}
