import { Link as LinkMUI, Typography } from '@mui/material'
import Flex from './Flex'

const Link = ({ text, color, hoverColor, onClick, url, RightIcon, gap = '4px' }) => {
  return (
    <Flex row gap={gap} width="auto">
      <Typography
        onClick={onClick}
        sx={{
          textDecoration: 'underline',
          cursor: 'pointer',
          color: color ?? 'text.secondary',
          '&:hover': { color: hoverColor ?? 'text.primary' },
          width: 'auto',
          wordBreak: 'break-word',
        }}
        {...(url && { component: LinkMUI, href: url, target: '_blank' })}
      >
        {text}
      </Typography>
      {RightIcon && <RightIcon />}
    </Flex>
  )
}

export default Link
