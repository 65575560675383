import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { usePatchComment } from 'api/hooks/patch'
import Flex from 'components/common/Flex'
import { TextEditor } from 'inputs/ControlledInputs'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'

const CommentEdit = ({ comment, setIsEditable }) => {
  const { mutateAsync: patchComment } = usePatchComment()
  const editorRef = useRef(null)
  const { id: commentId, body } = comment

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { body },
  })

  const onSubmit = async () => {
    await patchComment(
      { body: JSON.stringify(editorRef.current.getEditorState()), commentId },
      { onSuccess: () => setIsEditable(false) }
    )
  }

  return (
    <Flex component="form" gap="8px" pr="16px" onSubmit={handleSubmit(onSubmit)}>
      <TextEditor
        control={control}
        name="body"
        disabled={isSubmitting}
        rules={{ required: 'Required' }}
        editorRef={editorRef}
      />
      <Flex row alignSelf="flex-end" gap="8px">
        <Button variant="text" onClick={() => setIsEditable(false)} disabled={isSubmitting}>
          Cancel
        </Button>
        <LoadingButton type="submit" variant="contained" color="primary" loading={isSubmitting}>
          Save
        </LoadingButton>
      </Flex>
    </Flex>
  )
}

export default CommentEdit
