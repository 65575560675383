import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { useGetRegistry } from 'api/hooks/get'
import { usePatchRegistry } from 'api/hooks/patch'
import Flex from 'components/common/Flex'
import { useEvent } from 'contexts/EventContext'
import { useAuth } from 'hooks/useAuth'
import { Select, Switch, TextField } from 'inputs/ControlledInputs'
import { useForm, useWatch } from 'react-hook-form'
import { getObjectClone } from 'utils'

const SupplyAddEditItemModal = ({
  category,
  name,
  quantity,
  alert,
  claimLimit,
  claims,
  totalClaimed,
}) => {
  const { user } = useAuth()
  const { isHost } = useEvent()
  const fetchRegistry = useGetRegistry()
  const { mutateAsync: updateRegistry } = usePatchRegistry()
  const isAdd = !quantity

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      category: category ?? 'add_new_category',
      newCategory: '',
      name: name ?? '',
      quantity: quantity ?? 1,
      alert: alert ?? false,
      claimLimit: claimLimit ?? '1',
      claims: claims ?? {},
    },
  })

  const entries = fetchRegistry.data.entries[0]
  const categories = Object.keys(entries)?.map((category) => ({
    label: category,
    value: category,
  }))

  const quantityValue = useWatch({
    control,
    name: 'quantity',
  })
  const categoryValue = useWatch({
    control,
    name: 'category',
  })
  const isNewCategory = categoryValue === 'add_new_category'

  const onSubmit = async (data) => {
    const updatedEntries = getObjectClone(entries)

    const newItem = {
      quantity: +data.quantity,
      alert: data.alert,
      claimLimit: data.quantity === 1 ? '1' : data.claimLimit,
      claims: data.claims,
      createdBy: user.id,
    }

    if (isAdd) {
      if (data.category === 'add_new_category') {
        updatedEntries[data.newCategory] = { [data.name]: newItem }
      } else {
        updatedEntries[data.category][data.name] = newItem
      }
    } else {
      if (data.name !== name) {
        delete updatedEntries[category][name]
      }
      updatedEntries[data.category][data.name] = newItem
    }

    await updateRegistry({ entries: [updatedEntries] })
  }

  return (
    <Flex component="form" gap="16px" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" alignSelf="center" mb="12px">
        {isAdd ? 'Add item' : `Edit ${name}`} {isAdd && category && `to ${category}`}
      </Typography>
      {isAdd && !category && (
        <Select
          control={control}
          name="category"
          label="Category"
          disabled={isSubmitting || category}
          rules={{ required: 'Required' }}
          options={[...categories, { label: 'Add new category', value: 'add_new_category' }]}
        />
      )}
      {isNewCategory && (
        <TextField
          control={control}
          name="newCategory"
          label="Category"
          disabled={isSubmitting}
          rules={{
            required: 'Required',
            validate: (value) => {
              return (
                !categories.map(({ value }) => value).includes(value) || 'Category already exists'
              )
            },
          }}
        />
      )}
      <TextField
        control={control}
        name="name"
        label="Name"
        disabled={isSubmitting}
        rules={{
          required: 'Required',
          validate: (value) => {
            return (
              !isAdd ||
              categoryValue === 'add_new_category' ||
              !entries[categoryValue][value] ||
              'Item already exists'
            )
          },
        }}
      />
      <TextField
        control={control}
        name="quantity"
        label="Quantity"
        disabled={isSubmitting}
        rules={{
          required: 'Required',
          min: {
            value: totalClaimed || 1,
            message: `Must be at least ${totalClaimed || 1}`,
          },
        }}
      />
      {quantityValue > 1 && (
        <Select
          control={control}
          name="claimLimit"
          label="Claim Limit"
          disabled={isSubmitting}
          rules={{
            required: 'Required',
            validate: (value) => {
              if (value === '1' && !isAdd) {
                return (
                  Object.values(claims).every(({ quantity }) => quantity === 1) ||
                  'There are claims with multiple quantities'
                )
              }
            },
          }}
          options={[
            { label: '1', value: '1' },
            { label: 'Unlimited', value: 'unlimited' },
          ]}
        />
      )}
      {isHost && (
        <Switch
          control={control}
          name="alert"
          label="Alert attendees if unclaimed"
          disabled={isSubmitting}
        />
      )}
      <LoadingButton
        type="submit"
        sx={{ alignSelf: 'flex-end' }}
        onClick={handleSubmit}
        loading={isSubmitting}
      >
        Save
      </LoadingButton>
    </Flex>
  )
}

export default SupplyAddEditItemModal
